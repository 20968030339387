import React, { useCallback, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "../assets/css/images.css";
import {
	Masnory1,
	Masnory10,
	Masnory11,
	Masnory12,
	Masnory2,
	Masnory3,
	Masnory4,
	Masnory5,
	Masnory6,
	Masnory7,
	Masnory8,
	Masnory9,
} from "../constant/index";

const Masnory = () => {
	const [isZoomed, setIsZoomed] = useState(false);

	const handleZoomChange = useCallback((shouldZoom) => {
		setIsZoomed(shouldZoom);
	}, []);
	const cartpage = () => {
		window.location.href = "/cart";
	};
	const wishlistpage = () => {
		window.location.href = "/MyFavourite";
	};
	const images = [
		Masnory1,
		Masnory2,
		Masnory3,
		Masnory4,
		Masnory5,
		Masnory6,
		Masnory7,
		Masnory8,
		Masnory9,
		Masnory10,
		Masnory11,
		Masnory12,
	];
	return (
		<>
			<div className="masonry_box">
				<ResponsiveMasonry
					columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1100: 4 }}
				>
					<Masonry columnsCount={4} gutter="10px">
						{images.map((image, i) => (
							<div className="image_box">
								<figure>
									<div className="video-timer">
										<div className="icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="7.5"
												height="8.75"
												viewBox="0 0 7.5 8.75"
											>
												<path
													id="Polygon_11"
													data-name="Polygon 11"
													d="M3.511,1.481a1,1,0,0,1,1.728,0L7.873,6a1,1,0,0,1-.864,1.5H1.741A1,1,0,0,1,.877,6Z"
													transform="translate(7.5) rotate(90)"
													fill="#fff"
												/>
											</svg>
										</div>
										<h4 className="time">0:20</h4>
									</div>
									{/* <ControlledZoom
										isZoomed={isZoomed}
										onZoomChange={handleZoomChange}
									> */}
									<img
										key={i}
										src={image}
										style={{ width: "100%", display: "block" }}
									/>
									{/* </ControlledZoom> */}
								</figure>
								<div className="bottom-bar">
									<button onClick={cartpage} className="cart">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="27.249"
											height="32.286"
											viewBox="0 0 27.249 32.286"
										>
											<g
												id="Group_9002"
												data-name="Group 9002"
												transform="translate(-982 -526)"
											>
												<g
													id="Group_9001"
													data-name="Group 9001"
													transform="translate(982 526)"
												>
													<path
														id="Path_9820"
														data-name="Path 9820"
														d="M983.865,482.962a1.425,1.425,0,0,1-1.017-1.541q.02-10.845.008-21.691a1.3,1.3,0,0,1,1.482-1.487h3.611a8.554,8.554,0,0,1,3.587-5.989,8.288,8.288,0,0,1,5.37-1.566,8.606,8.606,0,0,1,8.1,7.556h.337c1.146,0,2.291,0,3.436,0a1.251,1.251,0,0,1,1.316,1.3q.005,6.006,0,12.012a1.261,1.261,0,1,1-2.519,0q0-5.186,0-10.372v-.4h-2.522v.339c0,1.114,0,2.228,0,3.342a1.263,1.263,0,1,1-2.521-.009c0-1.218,0-2.435,0-3.673H990.423v.343c0,1.114,0,2.228,0,3.342a1.263,1.263,0,1,1-2.521-.014c0-1.1,0-2.207,0-3.31v-.362h-2.506V480.44h.361q6.447,0,12.895,0a1.264,1.264,0,0,1,1.255,1.754,1.433,1.433,0,0,1-.906.768Zm18.557-24.729a6.015,6.015,0,0,0-6.377-5.018c-2.765.185-5.611,2.806-5.484,5.018Z"
														transform="translate(-982.848 -450.676)"
														fill="#fff"
													/>
													<path
														id="Path_9821"
														data-name="Path 9821"
														d="M1220.026,751.664a1.387,1.387,0,0,1-1.012-1.572c.021-.3,0-.606,0-.95-.393,0-.767,0-1.141,0a1.266,1.266,0,1,1-.01-2.522c.368,0,.734,0,1.152,0,0-.436-.01-.862,0-1.287a1.258,1.258,0,0,1,2.516,0c.01.418,0,.836,0,1.289.412,0,.8,0,1.182,0a1.263,1.263,0,1,1-.008,2.521c-.377,0-.754,0-1.173,0,0,.329-.018.641,0,.95a1.389,1.389,0,0,1-1.012,1.572Z"
														transform="translate(-1196.814 -719.378)"
														fill="#fff"
													/>
												</g>
											</g>
										</svg>
									</button>
									<button className="zoom">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="30.621"
											height="30.621"
											viewBox="0 0 30.621 30.621"
										>
											<g
												id="Icon_feather-zoom-in"
												data-name="Icon feather-zoom-in"
												transform="translate(1.5 1.5)"
											>
												<path
													id="Path_8711"
													data-name="Path 8711"
													d="M28.5,16.5a12,12,0,1,1-12-12A12,12,0,0,1,28.5,16.5Z"
													transform="translate(-4.5 -4.5)"
													fill="none"
													stroke="#fff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="3"
												/>
												<path
													id="Path_8712"
													data-name="Path 8712"
													d="M31.5,31.5l-6.525-6.525"
													transform="translate(-4.5 -4.5)"
													fill="none"
													stroke="#fff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="3"
												/>
												<path
													id="Path_8713"
													data-name="Path 8713"
													d="M16.5,12v9"
													transform="translate(-4.5 -4.5)"
													fill="none"
													stroke="#fff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="3"
												/>
												<path
													id="Path_8714"
													data-name="Path 8714"
													d="M12,16.5h9"
													transform="translate(-4.5 -4.5)"
													fill="none"
													stroke="#fff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="3"
												/>
											</g>
										</svg>
									</button>
									<a href={image} download={image} className="download">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="30"
											height="30"
											viewBox="0 0 30 30"
										>
											<g
												id="Icon_feather-download"
												data-name="Icon feather-download"
												transform="translate(1.5 1.5)"
											>
												<path
													id="Path_8715"
													data-name="Path 8715"
													d="M31.5,22.5v6a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3v-6"
													transform="translate(-4.5 -4.5)"
													fill="none"
													stroke="#fff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="3"
												/>
												<path
													id="Path_8716"
													data-name="Path 8716"
													d="M10.5,15,18,22.5,25.5,15"
													transform="translate(-4.5 -4.5)"
													fill="none"
													stroke="#fff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="3"
												/>
												<path
													id="Path_8717"
													data-name="Path 8717"
													d="M18,22.5V4.5"
													transform="translate(-4.5 -4.5)"
													fill="none"
													stroke="#fff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="3"
												/>
											</g>
										</svg>
									</a>
									<button onClick={wishlistpage} className="wishlist">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="29.25"
											height="28.125"
											viewBox="0 0 29.25 28.125"
										>
											<path
												id="Icon_ionic-ios-heart-empty"
												data-name="Icon ionic-ios-heart-empty"
												d="M24.75,3.938h-.07A8,8,0,0,0,18,7.594a8,8,0,0,0-6.68-3.656h-.07a7.949,7.949,0,0,0-7.875,7.945,17.115,17.115,0,0,0,3.361,9.33C10.969,27,18,32.063,18,32.063S25.031,27,29.264,21.213a17.115,17.115,0,0,0,3.361-9.33A7.949,7.949,0,0,0,24.75,3.938Zm2.925,16.116A53.929,53.929,0,0,1,18,29.587a54.01,54.01,0,0,1-9.675-9.541,15.169,15.169,0,0,1-2.981-8.163,5.966,5.966,0,0,1,5.92-5.97h.063a5.9,5.9,0,0,1,2.89.759,6.144,6.144,0,0,1,2.137,2,1.975,1.975,0,0,0,3.3,0,6.205,6.205,0,0,1,2.138-2,5.9,5.9,0,0,1,2.89-.759h.063a5.966,5.966,0,0,1,5.92,5.97A15.361,15.361,0,0,1,27.675,20.053Z"
												transform="translate(-3.375 -3.938)"
												fill="#fff"
											/>
										</svg>
									</button>
								</div>
							</div>
						))}
					</Masonry>
				</ResponsiveMasonry>
			</div>
		</>
	);
};

export default Masnory;
