import React, { useState, useEffect } from "react";
import PublicRoutes from "./routes/PublicRoutes";
// import axios from "axios";

function App() {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);
	return (
		<>
			{loading ? (
				<div className="socket-wrapper">
					<div class="socket">
						<div class="gel center-gel">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c1 r1">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c2 r1">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c3 r1">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c4 r1">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c5 r1">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c6 r1">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>

						<div class="gel c7 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>

						<div class="gel c8 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c9 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c10 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c11 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c12 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c13 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c14 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c15 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c16 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c17 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c18 r2">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c19 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c20 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c21 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c22 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c23 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c24 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c25 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c26 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c28 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c29 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c30 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c31 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c32 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c33 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c34 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c35 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c36 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
						<div class="gel c37 r3">
							<div class="hex-brick h1"></div>
							<div class="hex-brick h2"></div>
							<div class="hex-brick h3"></div>
						</div>
					</div>
				</div>
			) : (
				<PublicRoutes />
			)}
		</>
	);
}

export default App;
