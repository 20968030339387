import React, { useCallback, useState } from "react";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProfileHeader from "../../components/ProfileHeader";
import ProfileSidebar from "../../components/ProfileSidebar";
import "../../assets/css/DownloadHistory.css";
import {
	download1,
	download2,
	download3,
	download4,
	download5,
	download6,
	download7,
	download8,
	download9,
} from "../../constant";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";

const ArtistFavourite = () => {
	const [isZoomed, setIsZoomed] = useState(false);

	const handleZoomChange = useCallback((shouldZoom) => {
		setIsZoomed(shouldZoom);
	}, []);
	const downloadImages = [
		{
			id: 1,
			src: download1,
		},
		{
			id: 2,
			src: download2,
		},
		{
			id: 3,
			src: download3,
		},
		{
			id: 4,
			src: download4,
		},
		{
			id: 5,
			src: download5,
		},
		{
			id: 6,
			src: download6,
		},
		{
			id: 7,
			src: download7,
		},
		{
			id: 8,
			src: download8,
		},
		{
			id: 9,
			src: download9,
		},
		{
			id: 10,
			src: download1,
		},
		{
			id: 11,
			src: download2,
		},
		{
			id: 12,
			src: download3,
		},
		{
			id: 13,
			src: download4,
		},
		{
			id: 14,
			src: download5,
		},
		{
			id: 15,
			src: download6,
		},
		{
			id: 16,
			src: download7,
		},
		{
			id: 17,
			src: download8,
		},
		{
			id: 18,
			src: download9,
		},
	];
	return (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header Ends here */}
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-9">
							<div className="download-history-wrapper">
								<div className="download-heading-wrapper">
									<h3>My Favourite</h3>
									<div className="asset-wrapper">
										<div className="asset-list-wrapper">
											<h6>Asset Type</h6>
											<select name="" className="form-control" id="">
												<option value="Images">Images</option>
											</select>
										</div>
									</div>
								</div>
								<div className="download-content-wrapper">
									{downloadImages.map((data, index) => {
										return (
											<>
												<div key={index} className="download-img-wrapper">
													<figure>
														<ControlledZoom
															isZoomed={isZoomed}
															onZoomChange={handleZoomChange}
														>
															<img
																src={data?.src}
																className="img-fluid"
																alt=""
															/>
														</ControlledZoom>
													</figure>
													<div className="download-btns-wrapper">
														<button>
															<i className="fa-solid fa-magnifying-glass"></i>
														</button>
														<a href={data?.src} download>
															<i className="fa-solid fa-download"></i>
														</a>
														<button className="ms-3">
															<i class="fa-solid fa-trash"></i>
														</button>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer Ends here */}
		</>
	);
};

export default ArtistFavourite;
