import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-profile.css";
import { TwitterPicker } from "react-color";
import { profileAvatar } from "../../constant";
import Masnory from "../../components/Masnory";

const ArtistProfile = () => {
	const [filter, setFilter] = useState(false);
	const [background, setBackground] = useState("");
	const [hexa, setHexa] = useState(background);

	const [picker, setPicker] = useState(false);
	const toggleChecked = () => setPicker((value) => !value);

	const saveColors = (color) => {
		setBackground(color.hex);
		setHexa(color.hex);
	};

	const saveColors2 = (e) => {
		setHexa(e.target.value);
		setBackground(e.target.value);
	};
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Artist Profile Sec Start Here */}
			<section className="artist_profile">
				<div className="container">
					<div className="top-header">
						<div className="img-box">
							<figure>
								<img src={profileAvatar} alt="" className="img-fluid" />
							</figure>
						</div>
						<div className="content_wrapper">
							<h2>
								<span className="property">Artist :</span>{" "}
								<span className="value">John Doe</span>
							</h2>
							<h4 className="uploads">
								<span className="property">Total number of uploads :</span>
								<span className="value">1258765</span>
							</h4>
							<p className="description">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
								Integer feugiat scelerisque varius morbi enim nunc faucibus a.
								Volutpat maecenas volutpat blandit aliquam etiam erat velit.
								Tortor posuere ac ut consequat semper viverra. Vitae aliquet nec
								ullamcorper sit amet risus nullam eget. Massa sed elementum
								tempus egestas sed sed risus pretium.
							</p>
						</div>
					</div>
					<div className="filter-bar">
						<div className="showing-result">
							<p>Search result... Showing 1 of 0239...</p>
						</div>
						<div className="button-group">
							<button onClick={() => setFilter(true)} className="filter">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="25"
									height="19.985"
									viewBox="0 0 25 19.985"
								>
									<g
										id="Group_8672"
										data-name="Group 8672"
										transform="translate(-791.99 -405.606)"
									>
										<path
											id="Path_8719"
											data-name="Path 8719"
											d="M918.466,412.33v.349q0,5.9,0,11.8a2.05,2.05,0,0,1-.019.389.89.89,0,0,1-1.764-.139c0-.093,0-.186,0-.279q0-5.885.006-11.771a.408.408,0,0,0-.316-.473,3.4,3.4,0,1,1,2.435,0C918.7,412.245,918.6,412.282,918.466,412.33Zm.734-3.292a1.627,1.627,0,1,0-1.633,1.626A1.622,1.622,0,0,0,919.2,409.039Z"
											transform="translate(-113.083 0)"
											fill="#fff"
										/>
										<path
											id="Path_8720"
											data-name="Path 8720"
											d="M1040.667,422.112c0,.91.007,1.82,0,2.73a.891.891,0,1,1-1.781.02c0-.836-.009-1.673,0-2.51,0-.2-.064-.265-.242-.325a3.394,3.394,0,0,1-.018-6.406.323.323,0,0,0,.26-.371q-.012-4.239,0-8.477a.89.89,0,0,1,1.188-.917.914.914,0,0,1,.6.916q0,2.189,0,4.378c0,1.376,0,2.751,0,4.127,0,.167.027.258.21.322a3.394,3.394,0,0,1-.011,6.436C1040.8,422.057,1040.742,422.082,1040.667,422.112Zm-2.523-3.311a1.628,1.628,0,1,0,1.638-1.621A1.621,1.621,0,0,0,1038.144,418.8Z"
											transform="translate(-226.192 -0.18)"
											fill="#fff"
										/>
										<path
											id="Path_8721"
											data-name="Path 8721"
											d="M796.291,409.488c0,.93,0,1.86,0,2.79a.257.257,0,0,0,.206.3,3.395,3.395,0,0,1,0,6.435c-.187.065-.209.159-.208.323q.006,2.748,0,5.5a.886.886,0,0,1-1.236.871.915.915,0,0,1-.549-.92c0-1.795,0-3.59.006-5.384a.36.36,0,0,0-.279-.417,3.386,3.386,0,0,1,.023-6.381.325.325,0,0,0,.257-.375q-.013-2.72,0-5.44a.9.9,0,0,1,1.194-.917.885.885,0,0,1,.589.861C796.294,407.647,796.291,408.567,796.291,409.488Zm-.864,4.678a1.628,1.628,0,1,0,1.616,1.645A1.625,1.625,0,0,0,795.427,414.166Z"
											transform="translate(0 -0.188)"
											fill="#fff"
										/>
									</g>
								</svg>
								Filter
							</button>
						</div>
						<div className={filter ? "flter-box show" : "flter-box"}>
							<button onClick={() => setFilter(false)} className="cancel">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="36"
									height="36"
									fill="#fff"
									className="bi bi-x"
									viewBox="0 0 16 16"
								>
									<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
								</svg>
							</button>
							<div className="topbar">
								<button
									className="btn"
									type="button"
									onClick={() => setFilter(false)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="25"
										height="19.985"
										viewBox="0 0 25 19.985"
									>
										<g
											id="Group_8672"
											data-name="Group 8672"
											transform="translate(-791.99 -405.606)"
										>
											<path
												id="Path_8719"
												data-name="Path 8719"
												d="M918.466,412.33v.349q0,5.9,0,11.8a2.05,2.05,0,0,1-.019.389.89.89,0,0,1-1.764-.139c0-.093,0-.186,0-.279q0-5.885.006-11.771a.408.408,0,0,0-.316-.473,3.4,3.4,0,1,1,2.435,0C918.7,412.245,918.6,412.282,918.466,412.33Zm.734-3.292a1.627,1.627,0,1,0-1.633,1.626A1.622,1.622,0,0,0,919.2,409.039Z"
												transform="translate(-113.083 0)"
												fill="#fff"
											/>
											<path
												id="Path_8720"
												data-name="Path 8720"
												d="M1040.667,422.112c0,.91.007,1.82,0,2.73a.891.891,0,1,1-1.781.02c0-.836-.009-1.673,0-2.51,0-.2-.064-.265-.242-.325a3.394,3.394,0,0,1-.018-6.406.323.323,0,0,0,.26-.371q-.012-4.239,0-8.477a.89.89,0,0,1,1.188-.917.914.914,0,0,1,.6.916q0,2.189,0,4.378c0,1.376,0,2.751,0,4.127,0,.167.027.258.21.322a3.394,3.394,0,0,1-.011,6.436C1040.8,422.057,1040.742,422.082,1040.667,422.112Zm-2.523-3.311a1.628,1.628,0,1,0,1.638-1.621A1.621,1.621,0,0,0,1038.144,418.8Z"
												transform="translate(-226.192 -0.18)"
												fill="#fff"
											/>
											<path
												id="Path_8721"
												data-name="Path 8721"
												d="M796.291,409.488c0,.93,0,1.86,0,2.79a.257.257,0,0,0,.206.3,3.395,3.395,0,0,1,0,6.435c-.187.065-.209.159-.208.323q.006,2.748,0,5.5a.886.886,0,0,1-1.236.871.915.915,0,0,1-.549-.92c0-1.795,0-3.59.006-5.384a.36.36,0,0,0-.279-.417,3.386,3.386,0,0,1,.023-6.381.325.325,0,0,0,.257-.375q-.013-2.72,0-5.44a.9.9,0,0,1,1.194-.917.885.885,0,0,1,.589.861C796.294,407.647,796.291,408.567,796.291,409.488Zm-.864,4.678a1.628,1.628,0,1,0,1.616,1.645A1.625,1.625,0,0,0,795.427,414.166Z"
												transform="translate(0 -0.188)"
												fill="#fff"
											/>
										</g>
									</svg>
								</button>
								<h4>Filter</h4>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="radio-box">
										<div className="head">
											<h3>Categories</h3>
										</div>
										<ul className="list">
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="category_radio"
														checked
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Free</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="category_radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Premium</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="category_radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Standard</h4>
													</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="radio-box">
										<div className="head">
											<h3>Image / Video Type</h3>
										</div>
										<ul className="list">
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="image_radio"
														checked
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Image</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="image_radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Videos</h4>
													</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="radio-box">
										<div className="head">
											<h3>Trending Now</h3>
										</div>
										<ul className="list">
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
														checked="checked"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Camping</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Wildlife</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Laughter</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Color</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Color</h4>
													</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="radio-box tags">
										<div className="head">
											<h3>Tags</h3>
											<button className="clear">Clear All</button>
										</div>
										<ul>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Accessories</span>
												</button>
											</li>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Fashion</span>
												</button>
											</li>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Lifestyle</span>
												</button>
											</li>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Outdoors & Sunset</span>
												</button>
											</li>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Accessories</span>
												</button>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="radio-box colors">
										<div className="head">
											<h3>Color</h3>
										</div>
										<ul className="list">
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="color_radio"
														checked="checked"
													/>
													<span className="checkmark">
														<div
															className="dot red"
															style={{ backgroundColor: "#FF0000" }}
														></div>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="color_radio"
													/>
													<span className="checkmark">
														<div
															className="dot green"
															style={{ backgroundColor: "#31FF00" }}
														></div>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="color_radio"
													/>
													<span className="checkmark">
														<div
															className="dot blue"
															style={{ backgroundColor: "#00D3FF" }}
														></div>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="color_radio"
													/>
													<span className="checkmark">
														<div
															className="dot yellow"
															style={{ backgroundColor: "#DFFF00" }}
														></div>
													</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="radio-box color-picker">
										<div className="head">
											<h3>Hex Code</h3>
										</div>
										<div className="form-group">
											<div className="clip-field">
												<input
													type="text"
													value={hexa}
													onChange={saveColors2}
													placeholder="Enter Hex or select "
													className="form-control"
												/>
												<button
													onClick={toggleChecked}
													className="background"
													style={{ backgroundColor: hexa }}
												></button>
											</div>
											<TwitterPicker
												className={picker ? "picker-show" : ""}
												onChangeComplete={saveColors}
												color={hexa}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* Masnory Start Here */}
					<Masnory />
					{/* Masnory End Here */}
				</div>
			</section>
			{/* Artist Profile Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ArtistProfile;
