export const colourOptions = [
	{ value: "Accessories", label: "Accessories" },
	{ value: "Fashion", label: "Fashion" },
	{ value: "Lifestyle", label: "Lifestyle" },
	{ value: "Outdoors & Sunset", label: "Outdoors & Sunset" },
	{ value: "Lifestyle", label: "Lifestyle" },
	{ value: "Outdoors & Sunse", label: "Outdoors & Sunse" },
	{ value: "Accessories", label: "Accessories" },
	{ value: "Fashion", label: "Fashion" },
	{ value: "Lifestyle", label: "Lifestyle" },
	{ value: "Outdoors & Sunset", label: "Outdoors & Sunset" },
	{ value: "Drone", label: "Drone" },
];
