import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/resgister.css";

const RegisterContributor = () => {
	return (
		<>
			<Header />
			{/* register sec starts here */}
			<section className="register-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="register-card-wrapper">
								<div className="register-heading-wrapper">
									<h2>Become A Contributor</h2>
									<p>
										Already have an account ?{" "}
										<a href="/login-contributor">Login</a>
									</p>
								</div>
								<div className="register-form-wrapper mt-5">
									<form action="/login-contributor">
										<div className="form-group">
											<div className="pass-label-wrapper">
												<label htmlFor="">
													First Name <span>*</span>
												</label>
											</div>
											<input
												type="text"
												placeholder="Enter full name"
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Last Name <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter full name"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Enter email <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter your email"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Contact Number <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter number"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Password <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter password"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Confirm Password <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter password"
											/>
										</div>
										<div className="form-group">
											<button className="btn form-control" type="submit">
												Register
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* register sec ends here */}
			<Footer />
		</>
	);
};

export default RegisterContributor;
