import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { profile1, profile2, profile3, profile4, profile5 } from "../constant";

const ProfileSidebar = () => {
	const [active, setactive] = useState(0);
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<>
			{/* Profile Sidebar starts here */}
			<div className="profileSidebar">
				<div className="profile-menu-links-wrapper">
					<ul>
						<li>
							<a
								onClick={() => {
									setactive(0);
									navigate("/myaccount");
								}}
								className={
									location?.pathname === "/myaccount"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile1} className="img-fluid" alt="" />
								<span>My Profile</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(1);
									navigate("/MySubscription");
								}}
								className={
									location?.pathname === "/MySubscription"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile2} className="img-fluid" alt="" />
								<span>My Subscription</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(2);
									navigate("/MyFavourite");
								}}
								className={
									location?.pathname === "/MyFavourite"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile3} className="img-fluid" alt="" />
								<span>My Favourite</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(3);
									navigate("/Download-History");
								}}
								className={
									location?.pathname === "/Download-History"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile4} className="img-fluid" alt="" />
								<span>Download History</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(4);
									navigate("/BillingInformation");
								}}
								className={
									location?.pathname === "/BillingInformation"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile5} className="img-fluid" alt="" />
								<span>Billing Information</span>
							</a>
						</li>
					</ul>
				</div>
				<div className="profile-logout-btn-wrapper">
					<a href="/" className="btn">
						Sign Out
					</a>
				</div>
			</div>
			{/* Profile Sidebar ends here */}
		</>
	);
};

export default ProfileSidebar;
