import React, { useEffect, useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "../../assets/css/images.css";
import "../../assets/css/artist-masnary.css";
import {
  Masnory1,
  Masnory10,
  Masnory11,
  Masnory12,
  Masnory2,
  Masnory3,
  Masnory4,
  Masnory5,
  Masnory6,
  Masnory7,
  Masnory8,
  Masnory9,
} from "../../constant/index";

const ArtistMasnory = () => {
  const images = [
    Masnory1,
    Masnory2,
    Masnory3,
    Masnory4,
    Masnory5,
    Masnory6,
    Masnory7,
    Masnory8,
    Masnory9,
    Masnory10,
    Masnory11,
    Masnory12,
  ];
  return (
    <>
      <Header />
      {/* Artist-header starts here */}
      <AritstPageHeader />
      {/* Artist-header starts here */}

      {/* Blog Sec Start Here */}
      <section className="artist-masnary">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mas-heading-wrapper">
                <h2>Resources</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="masonry_box">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1100: 4 }}
                >
                  <Masonry columnsCount={4} gutter="10px">
                    {images.map((image, i) => (
                      <a href="/artist-recourses">
                        <div className="image_box">
                          <figure>
                            {/* <div className="video-timer">
														<div className="icon">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="7.5"
																height="8.75"
																viewBox="0 0 7.5 8.75"
															>
																<path
																	id="Polygon_11"
																	data-name="Polygon 11"
																	d="M3.511,1.481a1,1,0,0,1,1.728,0L7.873,6a1,1,0,0,1-.864,1.5H1.741A1,1,0,0,1,.877,6Z"
																	transform="translate(7.5) rotate(90)"
																	fill="#fff"
																/>
															</svg>
														</div>
														<h4 className="time">0:20</h4>
													</div> */}
                            <img
                              key={i}
                              src={image}
                              style={{ width: "100%", display: "block" }}
                            />
                          </figure>
                          <div className="bottom-bar">
                            <h3>Night Photography</h3>
                            <p>
                              Etiam ultricies nisi vel augue. Curabitur
                              ullamcorper ultricies nisi.
                            </p>
                          </div>
                        </div>
                      </a>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Blog Sec End Here */}
      <Footer />
    </>
  );
};

export default ArtistMasnory;
