import React from "react";

const Newsletter = () => {
	return (
		<>
			{/* NewsLetter starts here */}
			<section className="newsletter">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4">
							<div className="newsletter-content-wrapper">
								<h5>Sign Up For Newsletters</h5>
								<p>
									Get E-mail updates about our latest shop and Special Offers
								</p>
							</div>
						</div>
						<div className="col-lg-1"></div>
						<div className="col-lg-7">
							<div className="form-newsletter-wrapper">
								<form action="">
									<div className="input-group">
										<input
											type="email"
											className="form-control"
											placeholder="Your Email Address"
										/>
										<span className="input-group-btn">
											<button className="btn" type="submit">
												Submit
											</button>
										</span>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* NewsLetter ends here */}
		</>
	);
};

export default Newsletter;
