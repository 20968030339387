import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/cart.css";
import { Link } from "react-router-dom";
import { cart2 } from "../../constant";

const Cart2 = () => {
  const CartItems = [
    {
      id: 1,
      ItemId: "1584789721",
      PerDownload: "12 Downloads per month",
      Price: "249",
    },
    {
      id: 1,
      ItemId: "1584789721",
      PerDownload: "12 Downloads per month",
      Price: "249",
    },
    {
      id: 1,
      ItemId: "1584789721",
      PerDownload: "12 Downloads per month",
      Price: "249",
    },
    {
      id: 1,
      ItemId: "1584789721",
      PerDownload: "12 Downloads per month",
      Price: "249",
    },
  ];
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header Start Here */}

      {/* Cart Sec Start Here */}
      <section className="cart_sec cart2">
        <div className="container">
          <div className="testimonials-wrapper">
            <h1>My Cart</h1>
            <p>Lorem ipsum dolor sit amet, consectetur</p>
          </div>
          <div className="cart_warpper">
            <div className="row">
              {CartItems?.map((data, index) => (
                <div className="col-lg-6" key={index}>
                  <div className="cart-box main-cart-box">
                    <div className="cart-img-wrap">
                      <button className="cancel-btn">x</button>
                      <img src={cart2} className="img-fluid" alt="" />
                    </div>
                    <div className="cart-main-content-wrap">
                      <h5>
                        Item ID <span className="number">{data?.ItemId}</span>
                      </h5>
                      <h3>{data?.PerDownload}</h3>
                      <h2>${data?.Price}</h2>
                      <p>1200 x 800 at 300dpi</p>
                      <div className="button-group">
                        <a href="/pricing" className="package btn">
                          Change Package
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="total-wrapper">
            <div className="item-total">
              <h5>
                <span className="property">Item Total :</span>
                <span className="value">$50</span>
              </h5>
              <h5>
                <span className="property">Package charges</span>
                <span className="value">$0</span>
              </h5>
              <h5>
                <span className="property">Taxes Applied :</span>
                <span className="value">-$5</span>
              </h5>
            </div>
            <div className="bottom-bar">
              <h5>
                <span className="property">Order Total</span>
                <span className="value">$3025.00</span>
              </h5>
              <div className="button-group">
                <Link className="btn" to="/images">
                  Back To Shop
                </Link>
                <Link to="/checkout" className="btn">
                  Checkout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cart Sec End Here */}
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default Cart2;
