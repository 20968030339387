import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-release-form.css";

const ArtistReleaseForm = () => {
	const release_form = [
		{
			id: 1,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
		{
			id: 2,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
		{
			id: 3,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
		{
			id: 4,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
		{
			id: 5,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
	];
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);
	return (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}

			{/* release Sec Start Here */}
			<section className="release-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-9">
							<div className="release-heading-wrapper">
								<h2>Release Forms</h2>
							</div>
							<div className="release-table-wrapper">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th className="title-heading">Title</th>
												<th className="action-heading">Actions</th>
											</tr>
										</thead>
										<tbody>
											{release_form.map((item, index) => {
												return (
													<>
														<tr key={index}>
															<td className="title-row">
																<h6>{item?.title}</h6>
															</td>
															<td className="actions-wrap">
																<div className="btn-wrap">
																	<a href="#">View</a>
																	<a href="#">Download</a>
																</div>
															</td>
														</tr>
													</>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="paginations-wrapper">
								<ul>
									<li className="pagination-item">
										<button className="prev pagination-link">
											<i className="fa fa-arrow-left"></i>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>01.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link active pagination-link">
											{" "}
											<span>02.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>03.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>04.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="next pagination-link">
											<i className="fa fa-arrow-right"></i>
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* release Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistReleaseForm;
