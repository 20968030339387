import React, { useEffect, useState } from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/removal-content.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { contentimg, upload1, upload2, upload3, upload4 } from "../../constant";

const RemovalContent2 = () => {
	const [show, setShow] = useState(false);
	const [halfcol, sethalfcol] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [isSubscribed, setIsSubscribed] = useState(false);
	const handleChange = (event) => {
		if (event.target.checked) {
			console.log("✅ Checkbox is checked");
		} else {
			console.log("⛔️ Checkbox is NOT checked");
		}
		setIsSubscribed((current) => !current);
	};
	useEffect(() => {
		if (window.innerWidth <= 1280) {
			sethalfcol(true);
		}
	}, []);
	return (
		<>
			{/* Contact Page modal starts here */}
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				className="contactmodal remove-content"
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					{/* contact form modal starts here */}
					<div className="contact-form-modal-content-wrapper">
						<img src={contentimg} className="img-fluid remove-content" alt="" />
						<h2>
							We Have Received Your Request And Will Respond As Soon As Possible
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim{" "}
						</p>
						<a href="/" className="btn">
							Submit
						</a>
					</div>

					{/* contact form modal starts here */}
				</Modal.Body>
				{/* <Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary">Understood</Button>
				</Modal.Footer> */}
			</Modal>
			{/* Contact Page modal starts here */}
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className={halfcol ? "col-lg-7" : "col-lg-6"}>
							<div className="request-removal-wrapper">
								<div className="removal-heading-wrapper">
									<h3>Request For Removal OF Content</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim{" "}
									</p>
								</div>
								<div className="request-form-wrapper">
									<form action="">
										<div className="form-group">
											<label htmlFor="">
												Contributor Name <span></span>
											</label>
											<input
												type="text"
												placeholder="Enter contributor name"
												className="form-control"
												readOnly
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Membership Number <span></span>
											</label>
											<input
												type="text"
												placeholder="Enter membership number"
												className="form-control"
												readOnly
											/>
										</div>
										<div className="form-group row">
											<div className="col-lg-6">
												<label htmlFor="">
													Asset Type <span>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter Asset Type"
													className="form-control"
												/>
											</div>
											<div className="col-lg-6">
												<label htmlFor="">
													Asset Id Number <span>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter asset number"
													className="form-control"
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<h6>Why would you like us to remove this content?</h6>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check1"
													/>
													<label className="form-check-label" for="check1">
														It's not my best work
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check2"
													/>
													<label className="form-check-label" for="check2">
														The asset is no longer relevant
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check3"
													/>
													<label className="form-check-label" for="check3">
														I expected more revenue
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check4"
													/>
													<label className="form-check-label" for="check4">
														Terminating my membership
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check5"
													/>
													<label className="form-check-label" for="check5">
														I prefer not to say
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value={isSubscribed}
														onChange={handleChange}
														id="check8"
													/>
													<label className="form-check-label" for="check8">
														Other
													</label>
												</div>
											</div>
										</div>
										{isSubscribed && (
											<div className="form-group row">
												<div className="col-lg-12">
													<textarea
														className="form-control"
														name=""
														placeholder="Please provide some comments here * (cannot be blank )"
														id=""
														cols="30"
														rows="5"
													></textarea>
												</div>
											</div>
										)}
										<div className="form-group">
											<div className="divider-wrapper">
												<div className="divider"></div>
											</div>
										</div>
										<div className="form-group row align-items-center">
											<div className="col-lg-8">
												<div className="form-check read">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check11"
													/>
													<label className="form-check-label" for="check11">
														I have read and agree to Terms and Conditions
													</label>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="btn-wrapper">
													{/* <button type="submit" className="btn remove2">
														Save & Proceed
													</button> */}
													<Button
														type="button"
														className="btn remove2"
														variant="primary"
														onClick={handleShow}
													>
														Save & Proceed
													</Button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default RemovalContent2;
