import React from "react";
import "../../assets/css/product-detail.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
	ProductDetailImg,
	detailsgallery,
	copyimg,
	similar1,
	similar2,
	similar3,
	similar4,
	similar5,
	similar6,
	similar7,
	similar8,
	star,
} from "../../constant";
import SimilarImages from "../../components/SimilarImages";
import ReviewForm from "../../components/ReviewForm";
import SimilarVideo from "../../components/SimilarVideo";
import { Link } from "react-router-dom";

const ProductDetail2 = () => {
	return (
		<>
			{/* HeaderStart */}
			<Header />
			{/* Header End Here */}

			{/* Product Detail Start Here */}
			<section className="product-detail">
				<div className="container">
					<div className="row">
						<div className="col-lg-7">
							<div className="video-leftside">
								<div className="video-sec">
									<div className="video-timer">
										<div className="icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="7.5"
												height="8.75"
												viewBox="0 0 7.5 8.75"
											>
												<path
													id="Polygon_11"
													data-name="Polygon 11"
													d="M3.511,1.481a1,1,0,0,1,1.728,0L7.873,6a1,1,0,0,1-.864,1.5H1.741A1,1,0,0,1,.877,6Z"
													transform="translate(7.5) rotate(90)"
													fill="#fff"
												/>
											</svg>
										</div>
										<h4 className="time">0:20</h4>
									</div>
									<div className="img-preview">
										<img
											src={ProductDetailImg}
											alt="product-image"
											className="img-fluid"
										/>
									</div>
									<div className="bottom-bar">
										<div className="printest">
											<button>
												<span className="icon">
													<i className="fa-brands fa-pinterest-p"></i>
												</span>
												<span className="txt">Post on pinterest</span>
											</button>
										</div>
										<div className="other-action">
											<button>
												<span className="icon">
													<i className="fa-solid fa-copy"></i>
												</span>
												<span className="txt">More Like This</span>
											</button>
											<button>
												<span className="icon">
													<i className="fa-regular fa-heart"></i>
												</span>
												<span className="txt">Add To Favourite</span>
											</button>
											<button>
												<span className="icon">
													<i className="fa-solid fa-download"></i>
												</span>
												<span className="txt">Download Preview</span>
											</button>
											<button>
												<span className="icon">
													<i className="fa-solid fa-share-nodes"></i>
												</span>
												<span className="txt">Share</span>
											</button>
										</div>
									</div>
								</div>
								<div className="description">
									<div className="top-text">
										<h6>Description</h6>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Integer feugiat scelerisque varius morbi enim nunc
											faucibus a. Volutpat maecenas volutpat blandit aliquam
											etiam erat velit. Tortor posuere ac ut consequat semper
											viverra. Vitae aliquet nec ullamcorper sit amet risus
											nullam eget. Massa sed elementum tempus egestas sed{" "}
										</p>
									</div>
									<div className="details">
										<h6>Details</h6>
										<div className="col-md-10">
											<div className="row">
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">Category :</span>
														<span className="answer">Transportation</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data right-side">
														<span className="question">Duration of Clip :</span>
														<span className="answer">0 min 25 sec</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">Asset Type :</span>
														<span className="answer">Video</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data right-side">
														<span className="question">Frame Rate :</span>
														<span className="answer">30fps</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">Asset Style :</span>
														<span className="answer">Video</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data right-side">
														<span className="question">Codec(s) :</span>
														<span className="answer">H.264</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">File Style :</span>
														<span className="answer">mp4</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data right-side">
														<span className="question">Capture Date :</span>
														<span className="answer">20 / 10 / 2022</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">File Size :</span>
														<span className="answer">200 MB</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data right-side">
														<span className="question">Capture Location :</span>
														<span className="answer">Unknown</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">Image Size :</span>
														<span className="answer">1280px x 720px</span>
													</div>
												</div>
												<div className="col-md-6"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-5">
							<div className="about-product">
								<div className="top-heading">
									<span>Videos / Seaview</span>
									<h2>Beach View Island</h2>
								</div>
								<div className="product-data">
									<div className="id-availalbality">
										<span className="item-id">Item ID : 1584789721</span>
										<span className="availability">
											Available In Subscription
										</span>
									</div>
									<div className="artist-color">
										<div className="artist">
											<Link to="/artist-profile">
												<span>
													Artist : <span className="name">John Doe</span>
												</span>
											</Link>
										</div>
										<div className="colors">
											<span>Color Pallete :</span>
											<div className="colors-list">
												<span
													className="single-color"
													style={{ backgroundColor: "#589655" }}
												>
													#589655
												</span>
												<span
													className="single-color"
													style={{ backgroundColor: "#623e00" }}
												>
													#623e00
												</span>
												<span
													className="single-color"
													style={{ backgroundColor: "#5747b2" }}
												>
													#5747b2
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className="resolutionlists">
									<div className="single-resolution">
										<input type="radio" id="test1" name="radio-group" checked />
										<label htmlFor="test1">
											<h6>$79 (720P) HD</h6>
											<p>1280 x 720</p>
										</label>
									</div>
									<div className="single-resolution">
										<input type="radio" id="test2" name="radio-group" />
										<label htmlFor="test2">
											<h6>$149 (1080P) Full HD</h6>
											<p>1920 x 1080</p>
										</label>
									</div>
									<div className="single-resolution">
										<input type="radio" id="test3" name="radio-group" />
										<label htmlFor="test3">
											<h6>$319 (4k) Ultra HD</h6>
											<p>3840 x 2160</p>
										</label>
									</div>
									<div className="abcd px-4">
										<a href="/cart" className="btn d-block w-100 mt-3">
											Add To Cart
										</a>
									</div>
								</div>
								<div className="tags">
									<span className="heading">Related Tags:</span>
									<div className="tags-flex">
										<button type="button" className="single_tag">
											<span className="tag-name">Accessories</span>
											<span className="cross">x</span>
										</button>
										<button type="button" className="single_tag">
											<span className="tag-name">Fashion</span>
											<span className="cross">x</span>
										</button>
										<button type="button" className="single_tag">
											<span className="tag-name">Lifestyle</span>
											<span className="cross">x</span>
										</button>
										<button type="button" className="single_tag">
											<span className="tag-name">Outdoors & Sunset</span>
											<span className="cross">x</span>
										</button>
									</div>
									<div className="image-gallery mt-4">
										<div className="row">
											<div className="col-md-4 mt-3">
												<figure>
													<img
														src={detailsgallery}
														className="img-fluid gal-img"
														alt=""
													/>
												</figure>
											</div>
											<div className="col-md-4 mt-3">
												<figure>
													<img
														src={detailsgallery}
														className="img-fluid gal-img"
														alt=""
													/>
												</figure>
											</div>
											<div className="col-md-4 mt-3">
												<figure>
													<img
														src={detailsgallery}
														className="img-fluid gal-img"
														alt=""
													/>
												</figure>
											</div>
											<div className="col-md-4 mt-3">
												<figure>
													<img
														src={detailsgallery}
														className="img-fluid gal-img"
														alt=""
													/>
												</figure>
											</div>
											<div className="col-md-4 mt-3">
												<figure>
													<img
														src={detailsgallery}
														className="img-fluid gal-img"
														alt=""
													/>
												</figure>
											</div>
											<div className="col-md-4 mt-3">
												<figure>
													<img
														src={detailsgallery}
														className="img-fluid gal-img"
														alt=""
													/>
												</figure>
											</div>
											<div className="col-md-4 mt-3">
												<figure>
													<img
														src={detailsgallery}
														className="img-fluid gal-img"
														alt=""
													/>
												</figure>
											</div>
											<div className="col-md-4 mt-3">
												<figure>
													<img
														src={detailsgallery}
														className="img-fluid gal-img"
														alt=""
													/>
												</figure>
											</div>
											<div className="col-md-4 mt-3">
												<button className="morelikethis form-control">
													<span>
														<img src={copyimg} alt="" /> More Like This
													</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Similar Images Start here */}
			<SimilarVideo />
			{/* Similar Images Start here */}
			{/* Review Form Start */}
			<ReviewForm />
			{/* Review Form End */}
			{/* Product Detail End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ProductDetail2;
