import React, { useState } from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/Paymentinfo.css";

const PaymentInfo = () => {
  const [edit, setEdit] = useState(false);
  const handleEdit = () => {
    setEdit(true);
  };
  return (
    <>
      <Header />
      {/* Profile Header starts here */}
      <ArtistHeader />
      {/* Profile Header Ends here */}
      {/* Artist starts here */}
      <section className="Artist">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <ArtistSidebar />
            </div>
            <div className="col-lg-9">
              <div className="my-account-wrapper payment">
                <div className="my-account-heading-wrapper">
                  <h3>Payment Information</h3>
                  <a href="/payment-cheque" className="btn">
                    Cheque
                  </a>
                </div>
                <div className="pyment-extra-heading">
                  <h5>Paypal</h5>
                </div>
                <div className="my-account-form-wrapper">
                  <form action="">
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="">
                          Paypal Email <span>*</span>
                        </label>
                        {edit ? (
                          <input
                            type="text"
                            placeholder="John Doe"
                            className="edit"
                          />
                        ) : (
                          <input type="text" placeholder="John Doe" readOnly />
                        )}
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="">
                          Paypal Username <span>*</span>
                        </label>
                        <div className="pass-btn-field-wrapper">
                          {edit ? (
                            <input
                              type="text"
                              placeholder="John Doe@example.com"
                              className="edit"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="John Doe@example.com"
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 mt-3">
                        {edit ? (
                          <button
                            onClick={() => setEdit(false)}
                            className="btn mt-4 "
                            type="button"
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={handleEdit}
                            className="btn"
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Artist ends here */}
      <Footer />
    </>
  );
};

export default PaymentInfo;
