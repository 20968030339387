import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
		title: {
			display: true,
			text: "Chart.js Line Chart",
		},
	},
};

const labels = [
	"",
	"FEB",
	"MAR",
	"APR",
	"MAY",
	"JUN",
	"JUL",
	"AUG",
	"SEP",
	"OCT",
	"NOV",
	"DEC",
];

export const data = {
	labels,
	datasets: [
		{
			fill: true,
			label: "Dataset 2",
			data: labels.map(() => faker.datatype.number({ min: 0, max: 400 })),
			borderColor: "#00BBFF",
			backgroundColor: "#043C69",
		},
	],
};

const ArtistChart = () => {
	console.log(data);
	return (
		<>
			<Line options={options} data={data} />
		</>
	);
};

export default ArtistChart;
