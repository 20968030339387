import React from 'react'
import { useEffect } from 'react';

const GoTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>

        </>
    )
}

export default GoTop