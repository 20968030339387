import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/sitemap.css";
import {
	aboutpageimg,
	storyimg,
	sue1,
	sue2,
	team1,
	team2,
	team3,
	team4,
	visionimg,
	sitemapimg2,
	sitearrow3,
	sitearrow4,
	sitemapimg3,
	sitemapimg1,
	sitearrow1,
	sitearrow2,
} from "../../constant";

const Sitemap = () => {
	return (
		<>
			<Header />
			{/* About page header starts here */}
			<section className="about-page-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="about-page-header-content-wrapper">
								<h1>Sitemap</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About page header ends here */}
			{/* Sitemap starts here */}
			<section className="sitemap-sec">
				<div className="container">
					<div className="row flex-column-reverse flex-lg-row">
						<div className="col-lg-4 ">
							<div className="sidebar-text-and-img-wrapper">
								<div className="site-bar-wrapper mt-5 pt-5">
									<div className="sitemap-card-wrapper">
										<div className="sitemap-img-wrapper">
											<figure>
												<img src={sitemapimg2} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="site-content-wrapper">
											<h4>Sitemap</h4>
											<p>
												Etiam ultricies nisi <br /> vel augue. rabitur
											</p>
										</div>
									</div>
									<div className="sitemap-card-wrapper">
										<div className="sitemap-img-wrapper">
											<figure>
												<img src={sitemapimg2} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="site-content-wrapper">
											<h4>Sitemap</h4>
											<p>
												Etiam ultricies nisi <br /> vel augue. rabitur
											</p>
										</div>
									</div>
									<div className="sitemap-card-wrapper">
										<div className="sitemap-img-wrapper">
											<figure>
												<img src={sitemapimg2} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="site-content-wrapper">
											<h4>Sitemap</h4>
											<p>
												Etiam ultricies nisi <br /> vel augue. rabitur
											</p>
										</div>
									</div>
									<div className="site-arrow-img3-wrapper">
										<figure>
											<img src={sitearrow4} className="img-fluid" alt="" />
										</figure>
									</div>
									<div className="sitemap-card-wrapper">
										<div className="sitemap-img-wrapper">
											<figure>
												<img src={sitemapimg3} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="site-content-wrapper">
											<h4>Sitemap</h4>
										</div>
									</div>
								</div>
								<div className="img-wrapper">
									<figure>
										<img src={sitearrow3} alt="" />
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="site-cards-wrapper">
								<div className="site-card-wrapper">
									<div className="site-img-wrapper">
										<img src={sitemapimg1} className="img-fluid" alt="" />
									</div>
									<div className="site-content-wrapper">
										<h4>Sitemap</h4>
										<p>
											Etiam ultricies nisi vel augue. Curabitur <br />
											ullamcorper ultricies nisi. Nam eget dui.
										</p>
									</div>
								</div>
								<div className="site-arrow-img1-wrapper text-center mt-3">
									<figure>
										<img src={sitearrow1} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="site-card-row-wrapper first-row">
									<div className="row">
										<div className="col-lg-6">
											<div className="site-card-wrapper">
												<div className="site-img-wrapper">
													<img src={sitemapimg1} className="img-fluid" alt="" />
												</div>
												<div className="site-content-wrapper">
													<h4>Sitemap</h4>
													<p>
														Etiam ultricies nisi vel augue. Curabitur <br />
														ullamcorper ultricies nisi. Nam eget dui.
													</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="site-card-wrapper">
												<div className="site-img-wrapper">
													<img src={sitemapimg1} className="img-fluid" alt="" />
												</div>
												<div className="site-content-wrapper">
													<h4>Sitemap</h4>
													<p>
														Etiam ultricies nisi vel augue. Curabitur <br />
														ullamcorper ultricies nisi. Nam eget dui.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="site-arrow-img2-wrapper text-center mt-4 mb-4">
									<figure>
										<img src={sitearrow2} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="site-card-row-wrapper">
									<div className="row">
										<div className="col-lg-6">
											<div className="site-card-wrapper">
												<div className="site-img-wrapper">
													<img src={sitemapimg1} className="img-fluid" alt="" />
												</div>
												<div className="site-content-wrapper">
													<h4>Sitemap</h4>
													<p>
														Etiam ultricies nisi vel augue. Curabitur <br />
														ullamcorper ultricies nisi. Nam eget dui.
													</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="site-card-wrapper">
												<div className="site-img-wrapper">
													<img src={sitemapimg1} className="img-fluid" alt="" />
												</div>
												<div className="site-content-wrapper">
													<h4>Sitemap</h4>
													<p>
														Etiam ultricies nisi vel augue. Curabitur <br />
														ullamcorper ultricies nisi. Nam eget dui.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Sitemap ends here */}
			<Footer />
		</>
	);
};

export default Sitemap;
