import React from "react";
import ArtistPageLinks from "./ArtistPageLinks";

const AritstPageHeader = () => {
	return (
		<>
			{/* artist page header starts here */}
			<section className="artist-page-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="heading-wrapper">
								<h1>Artist's Corner</h1>
								<p>
									Tip Of The Day: <a href="#">“Don’t Eat Yellow Snow”</a>
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<ArtistPageLinks />
						</div>
					</div>
				</div>
			</section>
			{/* artist page header ends here */}
		</>
	);
};

export default AritstPageHeader;
