import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	profile1,
	profile2,
	profile3,
	profile4,
	profile5,
	profile6,
	profile7,
	profile8,
	profile9,
} from "../constant";
const ArtistSidebar = () => {
	const [active, setactive] = useState(0);
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<>
			{/* Profile Sidebar starts here */}
			<div className="artistSidebar">
				<div className="artist-menu-links-wrapper">
					<ul>
						<li>
							<a
								onClick={() => {
									setactive(0);
									navigate("/artist-account");
								}}
								className={
									location?.pathname === "/artist-account"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile1} className="img-fluid" alt="" />
								<span>My Profile</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(1);
									navigate("/payment-info");
								}}
								className={
									location?.pathname === "/payment-info"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile6} className="img-fluid" alt="" />
								<span>Payment Information</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(2);
									navigate("/my-earnings");
								}}
								className={
									location?.pathname === "/my-earnings"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile7} className="img-fluid" alt="" />
								<span>My Earnings</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(3);
									navigate("/artist-favourite");
								}}
								className={
									location?.pathname === "/artist-favourite"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile3} className="img-fluid" alt="" />
								<span>My Favourite</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(4);
									navigate("/artist-report");
								}}
								className={
									location?.pathname === "/artist-report"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile8} className="img-fluid" alt="" />
								<span>Reports</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setactive(5);
									navigate("/upload-work");
								}}
								className={
									location?.pathname === "/upload-work"
										? "nav-item active"
										: "nav-item"
								}
								href="#"
							>
								<img src={profile9} className="img-fluid" alt="" />
								<span>Uploaded Work</span>
							</a>
						</li>
					</ul>
				</div>
				<div className="artist-logout-btn-wrapper">
					<a href="/" className="btn">
						Sign Out
					</a>
				</div>
			</div>
			{/* Profile Sidebar ends here */}
		</>
	);
};

export default ArtistSidebar;
