import React, { useEffect, useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-news.css";
import { BlogImg1, newsdetail } from "../../constant";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ArtistNewsDetail = () => {
  const [loading, setLoading] = useState(false);
  const back = () => {
    window.history.back();
  };
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      <Header />
      {/* Artist-header starts here */}
      <AritstPageHeader />
      {/* Artist-header starts here */}

      {/* News detail Sec Start Here */}
      <section className="news-detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-11">
              {loading ? (
                <>
                  <Skeleton
                    height="400px"
                    baseColor="#05092cc7"
                    containerClassName="avatar-skeleton"
                  />
                  <Skeleton
                    height="50px"
                    style={{ marginTop: 30 }}
                    baseColor="#05092cc7"
                    containerClassName="avatar-skeleton"
                  />
                  <Skeleton
                    height="30px"
                    style={{ marginTop: 30 }}
                    count={3}
                    baseColor="#05092cc7"
                    containerClassName="avatar-skeleton"
                  />
                </>
              ) : (
                <div className="news-detail-card-wrapper">
                  <div className="news-detail-img-wrapper">
                    <figure>
                      <img src={newsdetail} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="news-detail-content-wrapper">
                    <div className="news-detail-heading-wrapper">
                      <h2>Discover High Quality, Stock Photos And Vectors</h2>
                    </div>
                    <div className="news-detail-des-wrapper">
                      <p>
                        Etiam ultricies nisi vel augue. Curabitur ullamcorper
                        ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis parturient montes,ricies
                        nisi. Nam s. Cum sociis natoque penatibus et magnis dis
                        pricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis ppe and scrambled it to
                        make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic
                        typesetting, remaining essentially unchanged. It was
                        popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages, and more
                        recently with desktop publishing software like Aldus
                        PageMaker including versions of Lorem Ipsum.Etiam
                        ultricies nisi vel augue. Curabitur ullamcorper
                        ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis parturient montes,ricies
                        nisi. Nam s. Cum sociis natoque penatibus et magnis dis
                        pricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis ppe and scrambled it to
                        make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic
                        typesetting, remaining essentially unchanged. It was
                        popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages, and more
                        recently with desktop publishing software like Aldus
                        PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p>
                        Etiam ultricies nisi vel augue. Curabitur ullamcorper
                        ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis parturient montes,ricies
                        nisi. Nam s. Cum sociis natoque penatibus et magnis dis
                        pricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis ppe and scrambled it to
                        make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic
                        typesetting, remaining essentially unchanged. It was
                        popularised
                      </p>
                      <p>
                        Etiam ultricies nisi vel augue. Curabitur ullamcorper
                        ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis parturient montes,ricies
                        nisi. Nam s. Cum sociis natoque penatibus et magnis dis
                        pricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis ppe and scrambled it to
                        make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic
                        typesetting, remaining essentially unchanged. It was
                        popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages, and more
                        recently with desktop publishing software like Aldus
                        PageMaker including versions of Lorem Ipsum.Etiam
                        ultricies nisi vel augue. Curabitur ullamcorper
                        ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
                        natoque penatibus et magnis dis parturient montes,ricies
                        nisi. Nam s. Cum sociis natoque pe
                      </p>
                    </div>
                    <div className="go-to-previous-page">
                      <a onClick={back} href="#">
                        {" "}
                        <i className="fa fa-angle-left"></i> Go Back
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      {/* News detail Sec End Here */}
      <Footer />
    </>
  );
};

export default ArtistNewsDetail;
