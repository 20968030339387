import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/login.css";

const ForgetPassword = () => {
	return (
		<>
			<Header />
			{/* Login sec starts here */}
			<section className="login-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login-card-wrapper">
								<div className="login-heading-wrapper">
									<h2>Forgot Password</h2>
									<p>Create a new password</p>
								</div>
								<div className="login-form-wrapper">
									<form action="/">
										<div className="form-group">
											<label htmlFor="">
												New Password <span>*</span>
											</label>
											<input type="password" className="form-control" />
										</div>
										<div className="form-group">
											<label htmlFor="">
												Re-Enter New Password <span>*</span>
											</label>
											<input type="password" className="form-control" />
										</div>
										<div className="form-group">
											<button className="btn form-control" type="submit">
												Submit
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login sec ends here */}
			<Footer />
		</>
	);
};

export default ForgetPassword;
