import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/checkout.css";
import { PaymentIcon1, PaymentIcon2, Product1 } from "../../constant";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useRef } from "react";
import { Link } from "react-router-dom";

const Checkout = () => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const inputElement = useRef();

	const focusInput = () => {
		inputElement.current.focus();
	};
	const Closemodal = () => {
		setShow(false);
	};
	return (
		<>
			{/* Login modal starts from here */}
			<Modal show={show} onHide={handleClose} className="login-modal">
				{/* <Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header> */}
				<Modal.Body>
					<div className="login-card-wrapper">
						<button className="modal-close" onClick={Closemodal}>
							X
						</button>
						<div className="login-heading-wrapper">
							<h2>Login Your Account </h2>
							<p>
								Does'nt have an account yet ?{" "}
								<a href="/Register-page">Register</a>
							</p>
						</div>
						<div className="login-form-wrapper">
							<form action="/thank-you-2">
								<div className="form-group">
									<label htmlFor="">
										Enter Email <span>*</span>
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Enter your Email"
										ref={inputElement}
									/>
								</div>
								<div className="form-group">
									<div className="pass-label-wrapper">
										<label htmlFor="">
											Password <span>*</span>
										</label>
										<a href="/forget-password-email">Forgot Password?</a>
									</div>
									<input
										type="text"
										placeholder="Enter Password"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<button
										onClick={focusInput}
										className="btn form-control"
										type="submit"
									>
										Login
									</button>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* Login modal ends from here */}
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Checkout Sec Start Here */}
			<section className="checkout_sec">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>Checkout</h1>
					</div>
					<div className="checkout_wrapper">
						<div className="row">
							<div className="col-lg-7">
								<div className="checkout-form">
									<div className="topbar">
										<h4>Checkout details</h4>
										<p>
											Already Have an account? <a href="/login">Log in</a>
										</p>
									</div>
									<form action="">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label>First Name</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>Last Name</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>Phone</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>Email</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label>Address</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label>Suite, Apartment, etc </label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label>City</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label>Country/Region</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label>State</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label>Zip Code</label>
													<input
														type="text"
														placeholder="Type here.."
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="payment_method">
													<div className="heading">
														<h3>Payment Details</h3>
													</div>
													<div className="icon">
														<figure>
															<img src={PaymentIcon1} alt="" />
														</figure>
														<figure>
															<img src={PaymentIcon2} alt="" />
														</figure>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="col-lg-5">
								<div className="detail-box">
									<div className="heading">
										<h4>PRODUCT DETAILS</h4>
									</div>
									<div className="product-list">
										<div className="product-box">
											<div className="img-box">
												<figure className="mb-0">
													<img src={Product1} alt="" className="img-fluid" />
												</figure>
											</div>
											<div className="txt">
												<h4 className="name">Beach View Island</h4>
												<h2>$249 Large</h2>
												<p>1200 x 800 at 300dpi</p>
											</div>
										</div>
										<div className="product-box">
											<div className="img-box">
												<figure className="mb-0">
													<img src={Product1} alt="" className="img-fluid" />
												</figure>
											</div>
											<div className="txt">
												<h4 className="name">Beach View Island</h4>
												<h2>$249 Large</h2>
												<p>1200 x 800 at 300dpi</p>
											</div>
										</div>
										<div className="product-box">
											<div className="img-box">
												<figure className="mb-0">
													<img src={Product1} alt="" className="img-fluid" />
												</figure>
											</div>
											<div className="txt">
												<h4 className="name">Beach View Island</h4>
												<h2>$249 Large</h2>
												<p>1200 x 800 at 300dpi</p>
											</div>
										</div>
									</div>
									<div className="item-total">
										<h5>
											<span className="propety">Item Total :</span>
											<span className="value">$50</span>
										</h5>
										<h5>
											<span className="propety">Shipment and Delivery</span>
											<span className="value">$0</span>
										</h5>
										<h5>
											<span className="propety">Taxes Applied :</span>
											<span className="value">-$5</span>
										</h5>
									</div>
									<div className="order-total">
										<h5>
											<span className="property">Order Total</span>
											<span className="value">$3025.00</span>
										</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="bottom-bar">
							<div className="form-group">
								<input
									type="checkbox"
									id="privacyCheckbox"
									className="form-check-input"
								/>
								<label htmlFor="privacyCheckbox">
									Proident, sunt in culpa qui officia deserunt mollit anim id
									est laborum.
								</label>
							</div>
							<div className="button-group">
								{/* <button className="cart-btn">
									<i className="fa fa-angle-left" aria-hidden="true"></i> My
									Cart
								</button> */}
								<Link to="/cart" className="cart-btn">
									<i className="fa fa-angle-left" aria-hidden="true"></i> My
									Cart
								</Link>
								{/* <button className="btn" onClick={handleShow}>
									Checkout
								</button> */}
								<Link className="btn" to="/thank-you">
									Checkout
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Checkout Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Checkout;
