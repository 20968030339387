import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/help.css";
import { help1, help2, help3, help4, mail } from "../../constant";

const HowCanHelp = () => {
	return (
		<>
			<Header />
			{/* Help page header starts here */}
			<section className="help-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="help-card-wrapper">
								<div className="help-heading-wrapper">
									<h2>How Can We Help?</h2>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Molestie at elementum eu facilisis sed odio morbi
										quis commodo.
									</p>
								</div>
								{/* <div className="search-wrapper">
									<input type="text" placeholder="Search here..." />
									<button className="submit">
										<i className="fa fa-search"></i>
									</button>
								</div> */}
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Help page header ends here */}
			{/* Popular starts here */}
			<section className="popular-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="populat-heading-wrapper">
								<h2>Popular Topics</h2>
							</div>
							<div className="popular-cards-wrapper">
								<div className="popular-card-wrapper">
									<div className="popular-img-wrapper">
										<img src={help1} className="img-fluid" alt="" />
									</div>
									<div className="popular-content-wrapper">
										<h4>Billing and payments</h4>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
									</div>
								</div>
								<div className="popular-card-wrapper">
									<div className="popular-img-wrapper">
										<img src={help2} className="img-fluid" alt="" />
									</div>
									<div className="popular-content-wrapper">
										<h4>Billing and payments</h4>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
									</div>
								</div>
								<div className="popular-card-wrapper">
									<div className="popular-img-wrapper">
										<img src={help3} className="img-fluid" alt="" />
									</div>
									<div className="popular-content-wrapper">
										<h4>Billing and payments</h4>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
									</div>
								</div>
								<div className="popular-card-wrapper">
									<div className="popular-img-wrapper">
										<img src={help4} className="img-fluid" alt="" />
									</div>
									<div className="popular-content-wrapper">
										<h4>Billing and payments</h4>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
										<p>Lorem ipsum doler sit amet?</p>
									</div>
								</div>
							</div>
							<div className="cant-find-wrapper">
								<div className="cant-find-heading-wrapper">
									<h2>Can't find what you are looking for?</h2>
								</div>
								<div className="cant-find-card-wrapper">
									<div className="cant-img-wrapper">
										<img src={mail} className="img-fluid" alt="" />
									</div>
									<div className="cant-content-wrapper">
										<h4>Email Us</h4>
										<p>We'll get back to you within 24 hours</p>
									</div>
									<div className="cant-button-wrapper">
										{/* <a href="#" className="btn">
											Choose
										</a> */}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			{/* Popular ends here */}
			<Footer />
		</>
	);
};

export default HowCanHelp;
