import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/login.css";

const LoginCont = () => {
	return (
		<>
			<Header />
			{/* Login sec starts here */}
			<section className="login-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login-card-wrapper">
								<div className="login-heading-wrapper">
									<h2>Contributor Login</h2>
									<p>
										Become a contributor then? <a href="#">Register</a>
									</p>
								</div>
								<div className="login-form-wrapper">
									<form action="/artist-account">
										<div className="form-group">
											<label htmlFor="">
												Enter Email <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter your Email"
											/>
										</div>
										<div className="form-group">
											<div className="pass-label-wrapper">
												<label htmlFor="">
													Password <span>*</span>
												</label>
												<a href="#">Forgot Password?</a>
											</div>
											<input
												type="text"
												placeholder="Enter Password"
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<button className="btn form-control" type="submit">
												Login
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login sec ends here */}
			<Footer />
		</>
	);
};

export default LoginCont;
