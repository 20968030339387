import React from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/myearning.css";
import { calendar, current_table } from "../../constant";

const MyEarnings = () => {
	const Package = [
		{
			id: 1,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
		{
			id: 2,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
		{
			id: 3,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
		{
			id: 4,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
	];
	const current_payment = [
		{
			id: 1,
			productImg: current_table,
			productName: "Stock Image",
			price: 2.0,
			downloadTimes: 2564,
		},
		{
			id: 2,
			productImg: current_table,
			productName: "Stock Image",
			price: 2.0,
			downloadTimes: 2564,
		},
		{
			id: 3,
			productImg: current_table,
			productName: "Stock Image",
			price: 2.0,
			downloadTimes: 2564,
		},
		{
			id: 5,
			productImg: current_table,
			productName: "Stock Image",
			price: 2.0,
			downloadTimes: 2564,
		},
		{
			id: 6,
			productImg: current_table,
			productName: "Stock Image",
			price: 2.0,
			downloadTimes: 2564,
		},
		{
			id: 7,
			productImg: current_table,
			productName: "Stock Image",
			price: 2.0,
			downloadTimes: 2564,
		},
	];
	const perv_month = [
		{
			id: 1,
			date: "22-Oct-2022",
			price: 2.0,
			isPaid: false,
		},
		{
			id: 2,
			date: "22-Oct-2022",
			price: 2.0,
			isPaid: true,
		},
		{
			id: 3,
			date: "22-Oct-2022",
			price: 2.0,
			isPaid: false,
		},
		{
			id: 4,
			date: "22-Oct-2022",
			price: 2.0,
			isPaid: false,
		},
		{
			id: 5,
			date: "22-Oct-2022",
			price: 2.0,
			isPaid: true,
		},
		{
			id: 6,
			date: "22-Oct-2022",
			price: 2.0,
			isPaid: false,
		},
	];
	return (
		<>
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-9">
							<div className="earning-card-wrapper">
								<div className="my-earnings-wrapper">
									<div className="my-earnings-heading-wrapper">
										<h3>My Earnings</h3>
										<span className="month">
											<img src={calendar} className="img-fluid" alt="" />{" "}
											<h6>Monthly</h6>
										</span>
									</div>
								</div>
								<div className="download-cards-wrapper">
									{Package.map((item, index) => {
										return (
											<>
												<div key={index} className="download-card-wrapper">
													<h4>{item.coast}</h4>
													<h5>{item.qty}</h5>
												</div>
											</>
										);
									})}
								</div>
								<div className="divider-wrapper">
									<div className="divide"></div>
								</div>
								<div className="current-month-wrapper">
									<div className="current-month-heading-wrapper">
										<h2>Current Month Payments</h2>
									</div>
									<div className="current-table-wrapper">
										<div className="table-responsive">
											<table className="table">
												<thead>
													<th>
														<span></span>
													</th>
													<th className="pro-head">
														{" "}
														<span>Product Name</span>{" "}
													</th>
													<th className="pro-earn">
														<span>Total Earnings</span>
													</th>
													<th className="download-head">
														<span>Download Times</span>
													</th>
												</thead>
												<tbody>
													{current_payment.map((item, index) => {
														return (
															<>
																<tr>
																	<td className="pro-img">
																		<div className="pro-img-wrapper">
																			<figure>
																				<img
																					src={item?.productImg}
																					className="img-fluid"
																					alt=""
																				/>
																			</figure>
																		</div>
																	</td>
																	<td className="pro-name">
																		<h5>{item?.productName}</h5>
																	</td>
																	<td className="pro-price">
																		<h5>USD {item?.price}</h5>
																	</td>
																	<td className="pro-download">
																		<h5>{item?.downloadTimes}</h5>
																	</td>
																</tr>
															</>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="divider-wrapper">
									<div className="divide"></div>
								</div>
								<div className="prev-wrapper">
									<div className="prev-heading-wrapper">
										<h2>Previous Month Payments</h2>
									</div>
									<div className="row">
										<div className="col-lg-10">
											<div className="prev-table-wrapper">
												<table className="table-responsive">
													<thead>
														<th className="date-heading">
															<span>Date</span>
														</th>
														<th className="Earnings-heading">
															<span>Total Earnings</span>
														</th>
														<th className="Payment-heading">
															<span>Payment Status</span>
														</th>
													</thead>
													<tbody>
														{perv_month.map((item, index) => {
															return (
																<>
																	<tr>
																		<td className="date">{item?.date}</td>
																		<td className="price">USD {item?.price}</td>
																		<td className="status">
																			{!item?.isPaid ? (
																				<>
																					<span className="pending">
																						Pending
																					</span>{" "}
																				</>
																			) : (
																				<>
																					<span className="paid">Paid</span>{" "}
																				</>
																			)}
																		</td>
																	</tr>
																</>
															);
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default MyEarnings;
