import React, { useState, useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/contact.css";
import { contactmail, contactPhone } from "../../constant";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GoTop from "../../components/GoTop";
const Contact = () => {
	const inputEl = useRef(null);
	const onButtonClick = () => {
		// `current` points to the mounted text input element
		inputEl.current.focus();
		console.log(inputEl);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<>
			<GoTop />
			{/* Contact Page modal starts here */}
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				className="contactmodal"
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					{/* contact form modal starts here */}
					<div className="contact-form-modal-content-wrapper">
						<h2>Your Query Has Been Successfully Submitted</h2>
						<p>We get back to you Shortly</p>
					</div>

					{/* contact form modal starts here */}
				</Modal.Body>
				{/* <Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary">Understood</Button>
				</Modal.Footer> */}
			</Modal>
			{/* Contact Page modal starts here */}
			<Header />
			{/* Contact sec starts here */}
			<section className="contact">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="contact-us-card-wrapper">
								<div className="contact-heading-wrapper">
									<h2>Get In Touch</h2>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do <br /> eiusmod tempor incididunt ut labore et dolore
										magna aliqua.
									</p>
								</div>
								{/* <div className="contact-icon-wrapper">
									<div className="row">
										<div className="col-lg-6">
											<div className="single-contact-wrapper">
												<div className="single-contact-img-wrapper">
													<img
														src={contactPhone}
														className="img-fluid"
														alt=""
													/>
												</div>
												<div className="single-contact-content-wrapper">
													<h5>Mail</h5>
													<h4>Info@Example.Com</h4>
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="single-contact-wrapper">
												<div className="single-contact-img-wrapper">
													<img src={contactmail} className="img-fluid" alt="" />
												</div>
												<div className="single-contact-content-wrapper">
													<h5>Contact</h5>
													<h4>+123 456 789</h4>
												</div>
											</div>
										</div>
									</div>
								</div> */}
								<div className="contact-form-wrapper mt-5">
									<form action="">
										<div className="form-group">
											<label htmlFor="">
												First name <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter full name"
												ref={inputEl}
												onClick={onButtonClick}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Last Name <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter your email"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Email Address <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Email Address"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Contact Number (Optional)</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter Contact"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Message <span>*</span>
											</label>
											<textarea
												name=""
												className="form-control"
												placeholder="Enter your message"
												id=""
												cols="30"
												rows="8"
											></textarea>
										</div>
										<div className="form-group">
											<div className="btn-wrapper text-end">
												<Button
													type="button"
													className="btn"
													variant="primary"
													onClick={handleShow}
												>
													Send Message
												</Button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* Contact sec ends here */}
			<Footer />
		</>
	);
};

export default Contact;
