import React, { useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/login.css";

const Login = () => {
	const inputElement = useRef();

	const focusInput = () => {
		inputElement.current.focus();
	};
	return (
		<>
			<Header />
			{/* Login sec starts here */}
			<section className="login-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login-card-wrapper">
								<div className="login-heading-wrapper">
									<h2>Login Your Account </h2>
									<p>
										Don’t have an account? <a href="/Register-page">Register</a>
									</p>
								</div>
								<div className="login-form-wrapper">
									<form action="/myaccount">
										<div className="form-group">
											<label htmlFor="">
												Enter Email <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter your Email"
												ref={inputElement}
											/>
										</div>
										<div className="form-group">
											<div className="pass-label-wrapper">
												<label htmlFor="">
													Password <span>*</span>
												</label>
												<a href="/forget-password-email">Forgot Password?</a>
											</div>
											<input
												type="text"
												placeholder="Enter Password"
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<button
												onClick={focusInput}
												className="btn form-control"
												type="submit"
											>
												Login
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login sec ends here */}
			<Footer />
		</>
	);
};

export default Login;
