import React, { useEffect, useState } from "react";
import { profileAvatar } from "../constant";
import "../assets/css/ArtistHeader.css";
import { useLocation, useNavigate } from "react-router-dom";

const ArtistHeader = (props) => {
	// const navigate = useNavigate();
	const location = useLocation();
	console.log(location.pathname);
	const [filename, setfilename] = useState("");
	const hiddenFileInput = React.useRef(null);
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};
	const handleChange = (event) => {
		const fileUploaded = event.target.files[0].name;
		setfilename(fileUploaded);
		props.handleFile(fileUploaded);
	};
	const handleUpload = () => {
		window.location.href = "/upload";
	};

	return (
		<>
			{/* Profile Header starts here */}
			<section className="artistHeader">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="artist-heading-wrapper">
								<h1>Artist's Portal</h1>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="artist-avatar-wrapper">
								<div className="artist-img-wrapper">
									<figure>
										<img src={profileAvatar} className="img-fluid" alt="" />

										{location.pathname == "/artist-account" && (
											<>
												<button onClick={handleClick} className="changeimg">
													{" "}
													{filename ? filename : "Change image"}
												</button>
												<input
													type="file"
													ref={hiddenFileInput}
													onChange={handleChange}
													style={{ display: "none" }}
													accept="image/*"
												/>
											</>
										)}
									</figure>
								</div>
								<div className="artist-content-wrapper">
									<h4>
										Artist : <span className="name">John Doe</span>
									</h4>
									<h6>
										Total number of uploads :{" "}
										<span className="total-uploads">1258765</span>
									</h6>

									<button onClick={handleUpload} className="btn">
										Upload Content
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Profile Header ends here */}
		</>
	);
};

export default ArtistHeader;
