import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Newsletter from "../../components/Newsletter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
	aboutImg,
	aur1,
	aur10,
	aur11,
	aur12,
	aur2,
	aur3,
	aur4,
	aur5,
	aur6,
	aur7,
	aur8,
	aur9,
	explore1,
	explore2,
	explore3,
	photo,
	trend1,
	trend2,
	trend3,
	trend4,
	trend5,
	user,
	verified,
	bannerbg,
} from "../../constant";
import { Link } from "react-router-dom";

const Home = () => {
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 5000,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 5000,
				},
			},
		],
	};
	var bannersettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};
	var trendingsettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		responsive: [
			{
				breakpoint: 490,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	var Trendingsli = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: false,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 5000,
				},
			},
			{
				breakpoint: 490,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 5000,
				},
			},
		],
	};
	return (
		<>
			<Header />
			{/* BANNER starts here */}
			<section className="banner-sec">
				<Slider {...bannersettings}>
					<img src={bannerbg} className="img-fluid banner-bg-img" alt="" />
					<img src={bannerbg} className="img-fluid banner-bg-img" alt="" />
					<img src={bannerbg} className="img-fluid banner-bg-img" alt="" />
					<img src={bannerbg} className="img-fluid banner-bg-img" alt="" />
				</Slider>
				<div className="banner-slide-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="banner-content-wrapper">
									<h1>
										Discover High Quality, <br /> Stock Photos And Vectors
									</h1>
								</div>
							</div>
							<div className="col-lg-6"></div>
						</div>
						<div className="row banner-ends">
							<div className="col-lg-6">
								<div className="banner-explore-wrapper">
									<div className="banner-explore-card-wrapper1">
										<Link to="/images">
											<div className="banner-explore-content-wrapper">
												<h6>Explore</h6>
												<h5>Images</h5>
											</div>
										</Link>
									</div>
									<div className="banner-explore-card-wrapper2">
										<a href="#categories">
											<div className="banner-explore-content-wrapper">
												<h6>Explore</h6>
												<h5>categorIES</h5>
											</div>
										</a>
									</div>
									<div className="banner-explore-card-wrapper3">
										<Link to="/videos">
											<div className="banner-explore-content-wrapper">
												<h6>Explore</h6>
												<h5>videos</h5>
											</div>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row low-bane">
									<div className="col-lg-8">
										<div className="banner-lower-content-wrapper">
											<h2>A Home for Artists</h2>
											<p>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ut enim ad minim veniam, quis nostrud
												exercitation ullamco laboris nisi ut aliquip ex ea
												commodo consequat.
											</p>
										</div>
									</div>
									<div className="col-lg-4">
										<Link className="more" to="/contributors">
											<span>learn</span>
											<span>more</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="register-banner-btn-wrapper">
					<a href="/Register-buyer">
						<span className="big">Register Now</span>
						<span>And Get 12 Free Images</span>
					</a>
				</div>
			</section>
			{/* BANNER ends here */}
			{/* TRENDING starts here */}
			<section className="trending-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="trending-heading-wrapper text-center">
								<h4>See What's Trending</h4>
							</div>
						</div>
					</div>
					<div className="row pt-3">
						<div className="col-lg-12">
							<div className="trending-cards-row">
								<Slider {...Trendingsli}>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend1} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>laughter</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend2} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>Camping</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend3} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>Wildlife</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend4} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>Colours</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend5} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>Industry</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend1} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>laughter</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend2} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>Camping</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend3} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>Wildlife</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend4} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>Colours</h6>
										</div>
									</div>
									<div className="trending-card-wrapper">
										<div className="trending-img-wrapper">
											<figure>
												<img src={trend5} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="trending-content-wrapper">
											<h6>Industry</h6>
										</div>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* TRENDING ends here */}
			{/* Explore sec starts here */}
			<section className="explore">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="explore-heading-wrapper">
								<h2 className="text-center">Explore New Uploads</h2>
							</div>
						</div>
					</div>
					<div className="row pt-3">
						<div className="col-lg-4">
							<div className="explore-img-wrapper">
								<figure>
									<img src={explore1} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="explore-img-wrapper">
								<figure>
									<img src={explore2} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="explore-img-wrapper">
								<figure>
									<img src={explore3} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Explore sec ends here */}
			{/* TOP COLLECTION Starts here */}
			<section className="top-collection">
				<div className="container">
					<div className="row mb-3 align-items-center">
						<div className="col-lg-4"></div>
						<div className="col-lg-4">
							<div className="top-heading-wrapper">
								<h2>Top Collections</h2>
							</div>
						</div>
						<div className="col-lg-4">
							<Link className="coll-btn" to="/images">
								View All
							</Link>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4">
							<Link to="/images">
								<div className="profile-card-wrapper">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="100%"
										height="100%"
									>
										<line class="top" x1="0" y1="0" x2="900" y2="0" />
										<line class="left" x1="0" y1="460" x2="0" y2="-920" />
										<line class="bottom" x1="300" y1="460" x2="-600" y2="460" />
										<line class="right" x1="100%" y1="0" x2="100%" y2="1380" />
									</svg>
									<div className="profile-name-and-img-wrapper">
										<div className="profile-img-space-wrapper">
											{/* <div className="profile-img-wrapper">
                          <img className="user-photo" src={user} alt="" />
                          <img src={verified} className="verified" alt="" />
                        </div> */}
											<div className="profile-name-wrapper">
												<h5>Illustrations</h5>
												{/* <p>
                            Created By <span>Adam Levine</span>{" "}
                          </p> */}
											</div>
										</div>
										<div className="profile-wishlist-wrapper">
											<button className="btn">
												<i className="fa-regular fa-heart"></i>
											</button>
										</div>
									</div>
									<div className="profile-gallery-wrapper">
										<div className="item1">
											<img src={aur1} className="img-fluid pro1" alt="" />
										</div>
										<div className="item2">
											<img src={aur2} className="img-fluid pro2" alt="" />
											<div className="bottom-image">
												<img src={aur3} className="img-fluid pro3" alt="" />
												<img src={aur4} className="img-fluid pro4" alt="" />
											</div>
										</div>
									</div>
								</div>
							</Link>
						</div>
						<div className="col-lg-4">
							<Link to="/images">
								<div className="profile-card-wrapper">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="100%"
										height="100%"
									>
										<line class="top" x1="0" y1="0" x2="900" y2="0" />
										<line class="left" x1="0" y1="460" x2="0" y2="-920" />
										<line class="bottom" x1="300" y1="460" x2="-600" y2="460" />
										<line class="right" x1="100%" y1="0" x2="100%" y2="1380" />
									</svg>
									<div className="profile-name-and-img-wrapper">
										<div className="profile-img-space-wrapper">
											{/* <div className="profile-img-wrapper">
													<img className="user-photo" src={user} alt="" />
													<img src={verified} className="verified" alt="" />
												</div> */}
											<div className="profile-name-wrapper">
												<h5>Illustrations</h5>
												{/* <p>
                            Created By <span>Adam Levine</span>
                          </p> */}
											</div>
										</div>
										<div className="profile-wishlist-wrapper">
											<button className="btn">
												<i className="fa-regular fa-heart"></i>
											</button>
										</div>
									</div>
									<div className="profile-gallery-wrapper">
										<div className="item1">
											<img src={aur5} className="img-fluid pro1" alt="" />
										</div>
										<div className="item2">
											<img src={aur6} className="img-fluid pro2" alt="" />
											<div className="bottom-image">
												<img src={aur7} className="img-fluid pro3" alt="" />
												<img src={aur8} className="img-fluid pro4" alt="" />
											</div>
										</div>
									</div>
								</div>
							</Link>
						</div>
						<div className="col-lg-4">
							<Link to="/images">
								<div className="profile-card-wrapper">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="100%"
										height="100%"
									>
										<line class="top" x1="0" y1="0" x2="900" y2="0" />
										<line class="left" x1="0" y1="460" x2="0" y2="-920" />
										<line
											class="bottom"
											x1="100%"
											y1="460"
											x2="-600"
											y2="460"
										/>
										<line class="right" x1="100%" y1="0" x2="100%" y2="1380" />
									</svg>
									<div className="profile-name-and-img-wrapper">
										<div className="profile-img-space-wrapper">
											{/* <div className="profile-img-wrapper">
													<img className="user-photo" src={user} alt="" />
													<img src={verified} className="verified" alt="" />
												</div> */}
											<div className="profile-name-wrapper">
												<h5>Illustrations</h5>
												{/* <p>
                            Created By <span>Adam Levine</span>{" "}
                          </p> */}
											</div>
										</div>
										<div className="profile-wishlist-wrapper">
											<button className="btn">
												<i className="fa-regular fa-heart"></i>
											</button>
										</div>
									</div>
									<div className="profile-gallery-wrapper">
										<div className="item1">
											<img src={aur9} className="img-fluid pro1" alt="" />
										</div>
										<div className="item2">
											<img src={aur10} className="img-fluid pro2" alt="" />
											<div className="bottom-image">
												<img src={aur11} className="img-fluid pro3" alt="" />
												<img src={aur12} className="img-fluid pro4" alt="" />
											</div>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* TOP COLLECTION ends here */}
			<div className="container pt-5 mt-5">
				<div className="row">
					<div className="col-lg-12">
						<div className="explore-divider-wraper">
							<div className="divider-line"></div>
						</div>
					</div>
				</div>
			</div>
			{/* About us starts here */}
			<section className="about">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={aboutImg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-content-wrapper">
								<h2>About Us</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
								<h2>Our Mission</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About us ends here */}
			{/* Promotion sec starts here */}
			<section className="promotion">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="promotion-card-1-wrapper">
								<div className="promotion-card-1-content-wrapper">
									<p>Tips And Tricks</p>
									<h4>
										Get To Know More About <br /> Stock Images And Illustrations
									</h4>
									<Link to="/tips" className="btn">
										Learn More
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="promotion-card-2-wrapper">
								<div className="promotion-card-2-content-wrapper">
									<h4>PROMOTIONS</h4>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do <br /> eiusmod tempor incididunt ut labore et dolore
										magna aliqua.{" "}
									</p>
									<a href="/pricing" className="btn">
										Learn More
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Promotion sec ends here */}
			{/* Photo sec starts here */}
			<section className="photo-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="photo-heading-wrapper">
								<h2>Photo Of This Week</h2>
								<p>
									Discover the most outstanding collections in all topics of
									life.
								</p>
							</div>
						</div>
					</div>
					<div className="row mt-3 align-items-center">
						<div className="col-lg-7">
							<div className="photo-img-wrapper">
								<figure>
									<img src={photo} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="photo-content-wrapper">
								<p>
									Uploaded On <span>22-May-2022</span>
								</p>
								<h2>Nature Of World Photo</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Integer feugiat scelerisque varius morbi enim nunc faucibus a.
									Volutpat maecenas volutpat blandit aliquam etiam erat velit.
								</p>
								<p>
									Tortor posuere ac ut consequat semper viverra. Vitae aliquet
									nec ullamcorper sit amet risus nullam eget. Massa sed
									elementum tempus egestas sed sed risus pretium.
								</p>
								<div className="photo-illustration-wrapper">
									<div className="photo-img-name-wrapper">
										<div className="photo-img-wrapper">
											<figure>
												<img className="user-photo" src={user} alt="" />
												<img src={verified} className="verified" alt="" />
											</figure>
										</div>
										<div className="photo-name-wrapper">
											<h5>Illustrations</h5>
											<h6>
												Created By <span>Adam Levine</span>
											</h6>
										</div>
									</div>
									<div className="photo-btn-wrapper">
										<Link to="/artist-profile" className="btn">
											See more from this artist
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Photo sec ends here */}
			{/* FEATURED CATEGORIES starts here */}
			<section className="featured-cat" id="categories">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="featured-content-wrapper">
								<h2>Featured Categories</h2>
								<p>
									Discover the most outstanding collections in all topics of
									life.
								</p>
								<div className="all-cat-wrapper">
									<button className="all-cat">All Categories</button>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt-5 pt-5">
						<div className="col-lg-12">
							<div className="cat-fst-row-wrapper">
								<div className="cat-card-wrapper fst-cat">
									<div className="cat-content-wrapper">
										<h4>ILLUSTRATIONS</h4>
										{/* <div className="admin-name-img-wrapper">
                      <div className="admin-img-wrapper">
                        <img src={user} className="img-fluid" alt="" />
                        <span>By Adam Levine</span>
                      </div>
                    </div> */}
									</div>
								</div>
								<div className="cat-card-wrapper sec-cat">
									<div className="cat-content-wrapper">
										<h4>ILLUSTRATIONS</h4>
										{/* <div className="admin-name-img-wrapper">
                      <div className="admin-img-wrapper">
                        <img src={user} className="img-fluid" alt="" />
                        <span>By Adam Levine</span>
                      </div>
                    </div> */}
									</div>
								</div>
								<div className="cat-card-wrapper thir-cat">
									<div className="cat-content-wrapper">
										<h4>ILLUSTRATIONS</h4>
										{/* <div className="admin-name-img-wrapper">
                      <div className="admin-img-wrapper">
                        <img src={user} className="img-fluid" alt="" />
                        <span>By Adam Levine</span>
                      </div>
                    </div> */}
									</div>
								</div>
							</div>
							<div className="cat-sec-row-wrapper">
								<div className="cat-card-wrapper four-cat">
									<div className="cat-content-wrapper">
										<h4>ILLUSTRATIONS</h4>
										{/* <div className="admin-name-img-wrapper">
                      <div className="admin-img-wrapper">
                        <img src={user} className="img-fluid" alt="" />
                        <span>By Adam Levine</span>
                      </div>
                    </div> */}
									</div>
								</div>
								<div className="cat-card-wrapper five-cat">
									<div className="cat-content-wrapper">
										<h4>ILLUSTRATIONS</h4>
										{/* <div className="admin-name-img-wrapper">
                      <div className="admin-img-wrapper">
                        <img src={user} className="img-fluid" alt="" />
                        <span>By Adam Levine</span>
                      </div>
                    </div> */}
									</div>
								</div>
								<div className="cat-card-wrapper six-cat">
									<div className="cat-content-wrapper">
										<h4>ILLUSTRATIONS</h4>
										{/* <div className="admin-name-img-wrapper">
                      <div className="admin-img-wrapper">
                        <img src={user} className="img-fluid" alt="" />
                        <span>By Adam Levine</span>
                      </div>
                    </div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* FEATURED CATEGORIES ends here */}
			{/* Promotion sec starts here */}
			<section className="join">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="promotion-card-1-wrapper">
								<div className="promotion-card-1-content-wrapper">
									<p>Join Us</p>
									<h4>
										Join Us Today As
										<br /> A Contributing Artist
									</h4>
									<Link to="/contributors" className="btn">
										Learn More
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="promotion-card-2-wrapper">
								<div className="promotion-card-2-content-wrapper">
									<h5>
										Join Our <br /> Amazing Community
									</h5>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do <br /> eiusmod tempor incididunt ut labore et dolore
										magna aliqua.{" "}
									</p>
									<div className="promotion-social-wrapper">
										<ul>
											<li>
												<a href="#" className="facebook">
													<i className="fa-brands fa-facebook-f"></i>
												</a>
											</li>
											<li>
												<a href="#" className="twitter">
													<i class="fa-brands fa-linkedin-in"></i>
												</a>
											</li>
											<li>
												<a href="#" className="instagram">
													<i className="fa-brands fa-instagram"></i>
												</a>
											</li>
											<li>
												<a href="#" className="pinterest">
													<i className="fa-brands fa-pinterest-p"></i>
												</a>
											</li>
											<li>
												<a href="#" className="youtube">
													<i className="fa-brands fa-youtube"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Promotion sec ends here */}
			{/* COUNTER starts here */}
			{/* <section className="counter-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="counter-wrapper">
                <h2>25K</h2>
                <h5>Downloads</h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="counter-wrapper">
                <h2>25K</h2>
                <h5>Contributors</h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="counter-wrapper">
                <h2>25K</h2>
                <h5>Social Logins</h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="counter-wrapper">
                <h2>25K</h2>
                <h5>Registered Members</h5>
              </div>
            </div>
          </div>
        </div>
      </section> */}
			{/* COUNTER ends here */}
			{/* NewsLetter starts here */}
			<Newsletter />
			{/* NewsLetter ends here */}
			<Footer />
		</>
	);
};

export default Home;
