import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/thank-you.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const ThankYou = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Thank You Start Here */}
			<section className="thank_you">
				<div className="container">
					<div className="heading_wrapper">
						<h2>THANK YOU FOR PURCHASING</h2>
						<h4>YOUR DOWNLOAD LINK IS READY</h4>
						<div className="back-button">
							<Link to="/">Go back to homepage</Link>
						</div>
						<p className="note">
							<i>Check your mail for the paid receipt</i>
						</p>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-6">
							<div className="product-detail">
								<div className="cart-box-wrapper">
									<div className="title">
										<h3>PRODUCT DETAILS</h3>
									</div>
									<div className="cart-box">
										<button className="cancel-btn">x</button>
										<h5>
											Item ID <span className="number">1584789721</span>
										</h5>
										<h3>12 Downloads per month</h3>
										<h2>$249</h2>
										<p>
											paying on monthly basis ( can be cancelled at any time)
										</p>
									</div>
								</div>
								<div className="item-total">
									<h4>
										<span className="property">Item Total :</span>
										<span className="value">$50</span>
									</h4>
									<h4>
										<span className="property">Shipment and Delivery :</span>
										<span className="value">-$5</span>
									</h4>
									<h4>
										<span className="property">Taxes Applied :</span>
										<span className="value">$0</span>
									</h4>
								</div>
								<div className="order-total">
									<h4>
										<span className="property">Order Total</span>
										<span className="value">$3025.00</span>
									</h4>
								</div>
							</div>
							<div className="button-group">
								<Link className="btn" to="/thank-you-2">
									View Purchased Item
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Thank You End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ThankYou;
