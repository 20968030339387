import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ProfileHeader from "../../components/ProfileHeader";
import ProfileSidebar from "../../components/ProfileSidebar";
import "../../assets/css/Mysubscription.css";

const MySubscription = () => {
	const packagepage = () => {
		window.location.href = "/pricing";
	};
	return (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header Ends here */}
			{/* Profile Header starts here */}
			<ProfileHeader />
			{/* Profile Header Ends here */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ProfileSidebar />
						</div>
						<div className="col-lg-9">
							<div className="my-subscription-wrapper">
								<div className="sub-wrapper">
									<div className="my-subscription-heading-wrapper">
										<h2>My Subscription</h2>
										<button onClick={packagepage} className="change-sub">
											Change Subscription
										</button>
									</div>
									<div className="form-group row">
										<div className="col-lg-12">
											<div className="sub-pack-wrapper">
												<h6>BASIC</h6>
												<h3>$ 4.99</h3>
												<h5>Standard License</h5>
											</div>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-lg-4">
											<div className="sub-pack-wrapper">
												<h6>PLAN EXPIRES</h6>
												<h3>26 - JUNE - 2022</h3>
												<h5>At 11:35 am GMT +5</h5>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="sub-pack-wrapper">
												<h6>VIDEO DOWNLOADS REMAINING</h6>
												<h3>345/450</h3>
												<h5>Ends 9 July 2022</h5>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="sub-pack-wrapper">
												<h6>VIDEO DOWNLOADS REMAINING</h6>
												<h3>345/450</h3>
												<h5>Ends 9 July 2022</h5>
											</div>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-lg-12">
											<div className="btn-wrapper">
												<a href="/pricing" className="btn">
													Renew Subscription
												</a>
												{/* <a href="#" className="btn ms-4">
													Cancel Subscription
												</a> */}
											</div>
										</div>
									</div>
								</div>
								<div className="divider-wrapper">
									<div className="divide"></div>
								</div>
								<div className="prev-wrapper">
									<div className="prev-heading-wrapper">
										<h3>Previous Purchase History</h3>
									</div>
									<div className="prev-table-wrapper">
										<div className="table-responsive">
											<table className="table">
												<thead>
													<tr>
														<th>
															<span className="date">Date</span>
														</th>
														<th>
															<span className="plan">Subscription Plan</span>
														</th>
														<th>
															<span className="payment">Payment</span>
														</th>
														<th>
															<span className="expire">Plan Expire</span>
														</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>22-Oct-2022</td>
														<td>Basic</td>
														<td>USD 2.00</td>
														<td>22-Oct-2022</td>
													</tr>
													<tr>
														<td>22-Oct-2022</td>
														<td>Standard</td>
														<td>USD 2.00</td>
														<td>22-Oct-2022</td>
													</tr>
													<tr>
														<td>22-Oct-2022</td>
														<td>Premium</td>
														<td>USD 2.00</td>
														<td>22-Oct-2022</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer Ends here */}
		</>
	);
};

export default MySubscription;
