import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { BlogImg1, BlogImg2, BlogImg3, RecentNews1 } from "../../constant";
import "../../assets/css/blog.css";
import { Link } from "react-router-dom";
import GoTop from "../../components/GoTop";

const Blog = () => {
	return (
		<>
			<GoTop />
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>Blog</h1>
					</div>
					<div className="row">
						<div className="col-lg-8">
							<div className="all_blogs">
								<Link to="/blog-detail">
									<div className="blog_box">
										<div className="blog-img">
											<figure>
												<img src={BlogImg1} alt="" className="img-fluid" />
											</figure>
											<div className="date">
												<h4>
													02 <span>Mar</span>
												</h4>
											</div>
										</div>
										<div className="blog-detail">
											<div className="posted">
												<h5>
													Stock Image / <span>John Doe</span>{" "}
												</h5>
											</div>
											<div className="blog-description">
												<h2>Discover High Quality, Stock Photos And Vectors</h2>
												<p>
													Etiam ultricies nisi vel augue. Curabitur ullamcorper
													ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
													natoque penatibus et magnis dis parturient montes,
													nascetur ridiculus mus. Donec quam
												</p>
											</div>
										</div>
									</div>
								</Link>
								<div className="blog_box">
									<div className="blog-img">
										<figure>
											<img src={BlogImg2} alt="" className="img-fluid" />
										</figure>
										<div className="date">
											<h4>
												02 <span>Mar</span>
											</h4>
										</div>
									</div>
									<div className="blog-detail">
										<div className="posted">
											<h5>
												Stock Image / <span>John Doe</span>{" "}
											</h5>
										</div>
										<div className="blog-description">
											<h2>Discover High Quality, Stock Photos And Vectors</h2>
											<p>
												Etiam ultricies nisi vel augue. Curabitur ullamcorper
												ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
												natoque penatibus et magnis dis parturient montes,
												nascetur ridiculus mus. Donec quam
											</p>
										</div>
									</div>
								</div>
								<div className="blog_box">
									<div className="blog-img">
										<figure>
											<img src={BlogImg3} alt="" className="img-fluid" />
										</figure>
										<div className="date">
											<h4>
												02 <span>Mar</span>
											</h4>
										</div>
									</div>
									<div className="blog-detail">
										<div className="posted">
											<h5>
												Stock Image / <span>John Doe</span>{" "}
											</h5>
										</div>
										<div className="blog-description">
											<h2>Discover High Quality, Stock Photos And Vectors</h2>
											<p>
												Etiam ultricies nisi vel augue. Curabitur ullamcorper
												ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
												natoque penatibus et magnis dis parturient montes,
												nascetur ridiculus mus. Donec quam
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="sidebar">
								<div className="categories sidebar-box">
									<div className="title">
										<h4>Categories</h4>
									</div>
									<ul className="list">
										<li>
											<a href="#">
												<span className="name">Stock Photo</span>
												<span className="count">(2)</span>
											</a>
										</li>
										<li>
											<a href="#">
												<span className="name">Stock Photo</span>
												<span className="count">(2)</span>
											</a>
										</li>
										<li>
											<a href="#">
												<span className="name">Stock Photo</span>
												<span className="count">(2)</span>
											</a>
										</li>
										<li>
											<a href="#">
												<span className="name">Stock Photo</span>
												<span className="count">(2)</span>
											</a>
										</li>
									</ul>
								</div>
								<div className="recent-news sidebar-box">
									<div className="title">
										<h4>Recent Posts</h4>
									</div>
									<ul className="list">
										<li>
											<a href="#">
												<div className="img-box">
													<figure className="mb-0">
														<img
															src={RecentNews1}
															alt=""
															className="img-fluid"
														/>
													</figure>
												</div>
												<div className="content">
													<p className="date">
														<i className="fa-solid fa-calendar"></i>
														22 march,2022
													</p>
													<p className="description">
														Etiam ultricies nisi vel augue. Etiam ultricies nisi
														vel augue. Curabitur
													</p>
												</div>
											</a>
										</li>
										<li>
											<a href="#">
												<div className="img-box">
													<figure className="mb-0">
														<img
															src={RecentNews1}
															alt=""
															className="img-fluid"
														/>
													</figure>
												</div>
												<div className="content">
													<p className="date">
														<i className="fa-solid fa-calendar"></i>
														22 march,2022
													</p>
													<p className="description">
														Etiam ultricies nisi vel augue. Etiam ultricies nisi
														vel augue. Curabitur
													</p>
												</div>
											</a>
										</li>
										<li>
											<a href="#">
												<div className="img-box">
													<figure className="mb-0">
														<img
															src={RecentNews1}
															alt=""
															className="img-fluid"
														/>
													</figure>
												</div>
												<div className="content">
													<p className="date">
														<i className="fa-solid fa-calendar"></i>
														22 march,2022
													</p>
													<p className="description">
														Etiam ultricies nisi vel augue. Etiam ultricies nisi
														vel augue. Curabitur
													</p>
												</div>
											</a>
										</li>
										<li>
											<a href="#">
												<div className="img-box">
													<figure className="mb-0">
														<img
															src={RecentNews1}
															alt=""
															className="img-fluid"
														/>
													</figure>
												</div>
												<div className="content">
													<p className="date">
														<i className="fa-solid fa-calendar"></i>
														22 march,2022
													</p>
													<p className="description">
														Etiam ultricies nisi vel augue. Etiam ultricies nisi
														vel augue. Curabitur
													</p>
												</div>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Blog;
