import React, { useState } from "react";
import { profileAvatar } from "../constant";
import "../assets/css/ProfileHeaderSidebar.css";
import { useLocation } from "react-router-dom";

const ProfileHeader = (props) => {
	const location = useLocation();
	const [filename, setfilename] = useState("");
	const hiddenFileInput = React.useRef(null);
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};
	const handleChange = (event) => {
		const fileUploaded = event.target.files[0].name;
		setfilename(fileUploaded);
		props.handleFile(fileUploaded);
	};
	return (
		<>
			{/* Profile Header starts here */}
			<section className="ProfileHeader">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="profile-heading-wrapper">
								<h1>My Profile</h1>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="artist-avatar-wrapper">
								<div className="artist-img-wrapper">
									<figure>
										<img src={profileAvatar} className="img-fluid" alt="" />

										{location.pathname == "/myaccount" && (
											<>
												<button onClick={handleClick} className="changeimg">
													{" "}
													{filename ? filename : "Change image"}
												</button>
												<input
													type="file"
													ref={hiddenFileInput}
													onChange={handleChange}
													style={{ display: "none" }}
													accept="image/*"
												/>
											</>
										)}
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Profile Header ends here */}
		</>
	);
};

export default ProfileHeader;
