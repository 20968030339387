import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/testimonials.css";
import { TestiImg1, TestiImg2, TestiImg3 } from "../../constant";
import GoTop from "../../components/GoTop";

const Testimonials = () => {
	const TestimonialData = [
		{
			id: 1,
			Image: TestiImg1,
			Name: "John Doe",
			Designation: "Illustration Designer",
			Testimonial: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer feugiat scelerisque varius morbi enim nunc faucibus a. Volutpat maecenas<br/><br/><br/> volutpat blandit aliquam etiam erat velit. Tortor posuere ac ut consequat semper viverra. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Massa sed elementum tempus egestas sed sed risus pretium.</p>`,
		},
		{
			id: 2,
			Image: TestiImg2,
			Name: "John Doe",
			Designation: "Illustration Designer",
			Testimonial: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer feugiat scelerisque varius morbi enim nunc faucibus a. Volutpat maecenas<br/><br/><br/> volutpat blandit aliquam etiam erat velit. Tortor posuere ac ut consequat semper viverra. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Massa sed elementum tempus egestas sed sed risus pretium.</p>`,
		},
		{
			id: 3,
			Image: TestiImg3,
			Name: "John Doe",
			Designation: "Illustration Designer",
			Testimonial: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer feugiat scelerisque varius morbi enim nunc faucibus a. Volutpat maecenas<br/><br/><br/>     volutpat blandit aliquam etiam erat velit. Tortor posuere ac ut consequat semper viverra. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Massa sed elementum tempus egestas sed sed risus pretium.</p>`,
		},
	];
	return (
		<>
			<GoTop />
			{/* Header Start Here */}
			<Header />
			{/* Header Start Here */}

			{/* Testimonial Sec Start Here */}
			<section className="testimonials">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>Testimonials</h1>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor
						</p>
					</div>
					<div className="all-testimonials">
						{TestimonialData?.map((data, index) => (
							<div className="testimonial-box" key={index}>
								<div className="name-box">
									<div className="img-box">
										<figure>
											<img src={data?.Image} alt="123" className="img-fluid" />
										</figure>
									</div>
									<div className="txt">
										<h4>{data?.Name}</h4>
										<p>{data?.Designation}</p>
									</div>
								</div>
								<div className="para">
									<p
										dangerouslySetInnerHTML={{ __html: data?.Testimonial }}
									></p>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
			{/* Testimonial Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer Start Here */}
		</>
	);
};

export default Testimonials;
