import React from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-corner.css";
import {
	aboutpageimg,
	visionimg,
	BlogImg1,
	BlogImg2,
	BlogImg3,
	RecentNews1,
} from "../../constant";
import { Link } from "react-router-dom";

const ArtistCorner = () => {
	return (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}
			{/* our mission starts here */}
			{/* <section className="our-mission" style={{ paddingTop: "100px" }}>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left">
								<figure>
									<img src={aboutpageimg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>Artist Of The Month</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nis
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* our mission ends here */}
			{/* our vision starts here */}
			{/* <section className="our-vision">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>Contest Winner</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nis
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-right">
								<figure>
									<img src={aboutpageimg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* our vision ends here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec artist-blog">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>Blogs</h1>
					</div>
					<div className="row">
						<div className="col-lg-8">
							<div className="all_blogs">
								<div className="blog_box">
									<div className="blog-img">
										<figure>
											<Link to="/artist-blog-detail">
												<img src={BlogImg1} alt="" className="img-fluid" />
											</Link>
										</figure>
										<div className="date">
											<h4>
												02 <span>Mar</span>
											</h4>
										</div>
									</div>
									<div className="blog-detail">
										<div className="posted">
											<h5>
												Stock Image /{" "}
												<span>
													{" "}
													<a href="/artist-account">John Doe</a>
												</span>{" "}
											</h5>
										</div>
										<div className="blog-description">
											<h2>Discover High Quality, Stock Photos And Vectors</h2>
											<p>
												Etiam ultricies nisi vel augue. Curabitur ullamcorper
												ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
												natoque penatibus et magnis dis parturient montes,
												nascetur ridiculus mus. Donec quam
											</p>
										</div>
									</div>
								</div>
								<div className="blog_box">
									<div className="blog-img">
										<figure>
											<Link to="/artist-blog-detail">
												<img src={BlogImg2} alt="" className="img-fluid" />
											</Link>
										</figure>
										<div className="date">
											<h4>
												02 <span>Mar</span>
											</h4>
										</div>
									</div>
									<div className="blog-detail">
										<div className="posted">
											<h5>
												Stock Image /{" "}
												<span>
													{" "}
													<a href="/artist-account">John Doe</a>
												</span>
											</h5>
										</div>
										<div className="blog-description">
											<h2>Discover High Quality, Stock Photos And Vectors</h2>
											<p>
												Etiam ultricies nisi vel augue. Curabitur ullamcorper
												ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
												natoque penatibus et magnis dis parturient montes,
												nascetur ridiculus mus. Donec quam
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="paginations-wrapper">
								<ul>
									<li className="pagination-item">
										<button className="prev pagination-link">
											<i className="fa fa-arrow-left"></i>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>01.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link active pagination-link">
											{" "}
											<span>02.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>03.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>04.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="next pagination-link">
											<i className="fa fa-arrow-right"></i>
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="sidebar">
								<div className="categories sidebar-box">
									<div className="title">
										<h4>Categories</h4>
									</div>
									<ul className="list">
										<li>
											<a href="#">
												<span className="name">Stock Photo</span>
												<span className="count">(2)</span>
											</a>
										</li>
										<li>
											<a href="#">
												<span className="name">Stock Photo</span>
												<span className="count">(2)</span>
											</a>
										</li>
										<li>
											<a href="#">
												<span className="name">Stock Photo</span>
												<span className="count">(2)</span>
											</a>
										</li>
										<li>
											<a href="#">
												<span className="name">Stock Photo</span>
												<span className="count">(2)</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistCorner;
