import React from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-account.css";
import { useState } from "react";

const ArtistAccount = (props) => {
  const [editaccount, Seteditaccount] = useState(false);
  const [editpass, seteditpass] = useState(false);
  const handleEdit = () => {
    Seteditaccount(true);
  };
  const handlePass = () => {
    seteditpass(true);
  };
  return (
    <>
      <Header />
      {/* Profile Header starts here */}
      <ArtistHeader />
      {/* Profile Header Ends here */}
      {/* Artist starts here */}
      <section className="Artist">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <ArtistSidebar />
            </div>
            <div className="col-lg-9">
              <div className="my-account-wrapper">
                <div className="my-account-heading-wrapper">
                  <h3>My Account</h3>
                  {!editaccount ? (
                    editpass ? (
                      ""
                    ) : (
                      <button onClick={handleEdit} className="btn">
                        Edit Account
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="my-account-form-wrapper">
                  {editpass ? (
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="">
                          Old Password <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="edit"
                          placeholder="Old Password"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="">
                          New Password <span>*</span>
                        </label>

                        <input
                          type="text"
                          className="edit"
                          placeholder="New Password"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="">
                          Confirm Password <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="edit"
                          placeholder="Confirm Password"
                        />
                      </div>
                      <div className="col-lg-4 ">
                        <button
                          type="button"
                          onClick={() => seteditpass(false)}
                          className="btn mt-5"
                        >
                          Save Password
                        </button>
                      </div>
                    </div>
                  ) : (
                    <form action="">
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <label htmlFor="">
                            First & last name <span>*</span>
                          </label>
                          {editaccount ? (
                            <input
                              type="text"
                              placeholder="John Doe"
                              className="edit"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="John Doe"
                              readOnly
                            />
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="">
                            Email <span>*</span>
                          </label>
                          {editaccount ? (
                            <input
                              type="text"
                              placeholder="John Doe@example.com"
                              className="edit"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="John Doe@example.com"
                              readOnly
                            />
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="">
                            Facebook email <span>*</span>
                          </label>
                          {editaccount ? (
                            <input
                              type="text"
                              placeholder="John Doe@example.com"
                              className="edit"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="John Doe@example.com"
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <label htmlFor="">
                            Password <span>*</span>
                          </label>
                          <div className="pass-btn-field-wrapper">
                            <input
                              type="password"
                              placeholder="************"
                              readOnly
                            />
                            <button
                              onClick={handlePass}
                              type="button"
                              className="edit-pass"
                            >
                              Edit Password
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="">
                            Subscription Package <span>*</span>
                          </label>
                          <input type="text" placeholder="Gold" readOnly />
                        </div>
                        <div className="col-lg-4 align-self-end">
                          {editaccount ? (
                            <button
                              onClick={() => Seteditaccount(false)}
                              className="btn"
                            >
                              Save Account Details
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Artist ends here */}
      <Footer />
    </>
  );
};

export default ArtistAccount;
