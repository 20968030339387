import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/agreement.css";

const Agreement = () => {
	return (
		<>
			{/* Header Start */}
			<Header />
			{/* Header End */}

			{/* Agreement Sec Start Here */}
			<section className="agreement_sec">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>Contributor Agreement</h1>
					</div>
					<div className="content-box">
						<div className="points">
							<ul className="list">
								<li>
									<span>1</span> Acceptance of Terms
								</li>
								<li>
									<span>2</span> Intellectual Property; Limited License to Users
								</li>
								<li>
									<span>3</span> Moonscape Trademarks
								</li>
								<li>
									<span>4</span> Information You Provide
								</li>
								<li>
									<span>5</span> Your Content
								</li>
								<li>
									<span>6</span> Infringement Claims / DMCA Notices
								</li>
								<li>
									<span>7</span> Limitations
								</li>
								<li>
									<span>8</span> Restriction and Termination of Use
								</li>
								<li>
									<span>9</span> Links to Third Party Sites
								</li>
								<li>
									<span>10</span> Warranties and Disclaimers
								</li>
								<li>
									<span>11</span> Indemnification
								</li>
								<li>
									<span>12</span> Miscellanous
								</li>
							</ul>
						</div>
						<div className="description-box">
							<h3>1.1 Acceptance OF Terms</h3>
							<p>
								orem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. At
								auctor urna nunc id cursus metus aliquam. Odio pellentesque diam
								volutpat commodo sed egestas egestas. Fames ac turpis egestas
								maecenas pharetra convallis posuere morbi. Mi proin sed libero
								enim sed. Nisl suscipit adipiscing bibendum est ultricies
								integer quis auctor elit. Egestas sed sed risus pretium. Aliquet
								nibh praesent tristique magna sit amet. Quam elementum pulvinar
								etiam non quam lacus. Ultrices dui sapien eget mi proin sed
								libero. Nisi porta lorem mollis aliquam ut porttitor.
								Ullamcorper eget nulla facilisi etiam dignissim diam. Eu
								ultrices vitae auctor eu augue ut lectus. Nisl suscipit
								adipiscing bibendum est ultricies integer. Condimentum vitae
								sapien pellentesque habitant morbi. Convallis a cras semper
								auctor. Condimentum id venenatis a condimentum vitae sapien
								pellentesque habitant.
								<br />
								<br />
								<br />
								Scelerisque in dictum non consectetur a erat nam. Lectus magna
								fringilla urna porttitor rhoncus dolor purus non enim. Egestas
								sed sed risus pretium. Donec adipiscing tristique risus nec. Ac
								turpis egestas maecenas pharetra convallis posuere morbi leo.
								Dignissim sodales ut eu sem integer vitae justo eget. Ac turpis
								egestas integer eget. Morbi tincidunt augue interdum velit
								euismod in pellentesque. Varius vel pharetra vel turpis.
								Pulvinar proin gravida hendrerit lectus a. Adipiscing elit
								pellentesque habitant morbi. Nunc sed id semper risus in
								hendrerit. Quisque sagittis purus sit amet volutpat consequat
								mauris.
							</p>
						</div>
						<div className="description-box">
							<h3>1.2 Acceptance OF Terms</h3>
							<p>
								orem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. At
								auctor urna nunc id cursus metus aliquam. Odio pellentesque diam
								volutpat commodo sed egestas egestas. Fames ac turpis egestas
								maecenas pharetra convallis posuere morbi. Mi proin sed libero
								enim sed. Nisl suscipit adipiscing bibendum est ultricies
								integer quis auctor elit. Egestas sed sed risus pretium. Aliquet
								nibh praesent tristique magna sit amet. Quam elementum pulvinar
								etiam non quam lacus. Ultrices dui sapien eget mi proin sed
								libero. Nisi porta lorem mollis aliquam ut porttitor.
								Ullamcorper eget nulla facilisi etiam dignissim diam. Eu
								ultrices vitae auctor eu augue ut lectus. Nisl suscipit
								adipiscing bibendum est ultricies integer. Condimentum vitae
								sapien pellentesque habitant morbi. Convallis a cras semper
								auctor. Condimentum id venenatis a condimentum vitae sapien
								pellentesque habitant.
								<br />
								<br />
								<br />
								Scelerisque in dictum non consectetur a erat nam. Lectus magna
								fringilla urna porttitor rhoncus dolor purus non enim. Egestas
								sed sed risus pretium. Donec adipiscing tristique risus nec. Ac
								turpis egestas maecenas pharetra convallis posuere morbi leo.
								Dignissim sodales ut eu sem integer vitae justo eget. Ac turpis
								egestas integer eget. Morbi tincidunt augue interdum velit
								euismod in pellentesque. Varius vel pharetra vel turpis.
								Pulvinar proin gravida hendrerit lectus a. Adipiscing elit
								pellentesque habitant morbi. Nunc sed id semper risus in
								hendrerit. Quisque sagittis purus sit amet volutpat consequat
								mauris.
							</p>
						</div>
						<div className="description-box">
							<h3>1.3 Acceptance OF Terms</h3>
							<p>
								orem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. At
								auctor urna nunc id cursus metus aliquam. Odio pellentesque diam
								volutpat commodo sed egestas egestas. Fames ac turpis egestas
								maecenas pharetra convallis posuere morbi. Mi proin sed libero
								enim sed. Nisl suscipit adipiscing bibendum est ultricies
								integer quis auctor elit. Egestas sed sed risus pretium. Aliquet
								nibh praesent tristique magna sit amet. Quam elementum pulvinar
								etiam non quam lacus. Ultrices dui sapien eget mi proin sed
								libero. Nisi porta lorem mollis aliquam ut porttitor.
								Ullamcorper eget nulla facilisi etiam dignissim diam. Eu
								ultrices vitae auctor eu augue ut lectus. Nisl suscipit
								adipiscing bibendum est ultricies integer. Condimentum vitae
								sapien pellentesque habitant morbi. Convallis a cras semper
								auctor. Condimentum id venenatis a condimentum vitae sapien
								pellentesque habitant.
							</p>
						</div>
					</div>
				</div>
			</section>
			{/* Agreement Sec End Here */}

			{/* Footer Start */}
			<Footer />
			{/* Footer End */}
		</>
	);
};

export default Agreement;
