import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/thank-you.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { thankyouimg } from "../../constant";

const Thankyou2 = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Thank You Start Here */}
			<section className="thank_you">
				<div className="container">
					<div className="heading_wrapper">
						<h2>THANK YOU FOR PURCHASING</h2>
						<h4>YOUR DOWNLOAD LINK IS READY</h4>

						<p className="note">
							<i>Check your mail for the paid receipt</i>
						</p>
						<div className="back-button">
							<Link to="/">Go back to homepage</Link>
						</div>
					</div>
					{/* <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="product-detail">
                <div className="cart-box-wrapper">
                  <div className="title">
                    <h3>PRODUCT DETAILS</h3>
                  </div>
                  <div className="cart-box">
                    <button className="cancel-btn">x</button>
                    <h5>
                      Item ID <span className="number">1584789721</span>
                    </h5>
                    <h3>12 Downloads per month</h3>
                    <h2>$249</h2>
                    <p>
                      paying on monthly basis ( can be cancelled at any time)
                    </p>
                  </div>
                </div>
                <div className="item-total">
                  <h4>
                    <span className="property">Item Total :</span>
                    <span className="value">$50</span>
                  </h4>
                  <h4>
                    <span className="property">Shipment and Delivery</span>
                  </h4>
                  <h4>
                    <span className="property">Taxes Applied :</span>
                    <span className="value">-$5</span>
                  </h4>
                  <h4 className="total">
                    <span className="value">$0</span>
                  </h4>
                </div>
                <div className="order-total">
                  <h4>
                    <span className="property">Order Total</span>
                    <span className="value">$3025.00</span>
                  </h4>
                </div>
              </div>
              <div className="button-group">
                <Link className="btn" to="#">
                  View Purchased Item
                </Link>
              </div>
            </div>
          </div> */}
					{/* thank you 2 details starts here */}
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="tahnk-wrapp">
								<div className="pro-headin-wrapper">
									<h5>PRODUCT DETAILS</h5>
								</div>
								<div className="beachs-cards-wrapper">
									<div className="beach-card-wrapper">
										<div className="beach-img-wrapper">
											<figure>
												<img src={thankyouimg} alt="" />
											</figure>
										</div>
										<div className="bech-content-wrapper">
											<h5>Beach View Island</h5>
											<h4>$249 Large</h4>
											<h6>1200 x 800 at 300dpi</h6>
											<a href="#" className="btn">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="17.031"
													height="17.031"
													viewBox="0 0 17.031 17.031"
												>
													<g
														id="Icon_feather-download"
														data-name="Icon feather-download"
														transform="translate(1 1)"
													>
														<path
															id="Path_8715"
															data-name="Path 8715"
															d="M19.531,22.5v3.34a1.67,1.67,0,0,1-1.67,1.67H6.17A1.67,1.67,0,0,1,4.5,25.84V22.5"
															transform="translate(-4.5 -12.479)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_8716"
															data-name="Path 8716"
															d="M10.5,15l4.175,4.175L18.851,15"
															transform="translate(-7.16 -9.155)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_8717"
															data-name="Path 8717"
															d="M18,14.521V4.5"
															transform="translate(-10.484 -4.5)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
													</g>
												</svg>

												<span>Download</span>
											</a>
										</div>
									</div>
									<div className="beach-card-wrapper">
										<div className="beach-img-wrapper">
											<figure>
												<img src={thankyouimg} alt="" />
											</figure>
										</div>
										<div className="bech-content-wrapper">
											<h5>Beach View Island</h5>
											<h4>$249 Large</h4>
											<h6>1200 x 800 at 300dpi</h6>
											<a href="#" className="btn">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="17.031"
													height="17.031"
													viewBox="0 0 17.031 17.031"
												>
													<g
														id="Icon_feather-download"
														data-name="Icon feather-download"
														transform="translate(1 1)"
													>
														<path
															id="Path_8715"
															data-name="Path 8715"
															d="M19.531,22.5v3.34a1.67,1.67,0,0,1-1.67,1.67H6.17A1.67,1.67,0,0,1,4.5,25.84V22.5"
															transform="translate(-4.5 -12.479)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_8716"
															data-name="Path 8716"
															d="M10.5,15l4.175,4.175L18.851,15"
															transform="translate(-7.16 -9.155)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_8717"
															data-name="Path 8717"
															d="M18,14.521V4.5"
															transform="translate(-10.484 -4.5)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
													</g>
												</svg>

												<span>Download</span>
											</a>
										</div>
									</div>
								</div>
								<div className="beachs-cards-wrapper">
									<div className="beach-card-wrapper">
										<div className="beach-img-wrapper">
											<figure>
												<img src={thankyouimg} alt="" />
											</figure>
										</div>
										<div className="bech-content-wrapper">
											<h5>Beach View Island</h5>
											<h4>$249 Large</h4>
											<h6>1200 x 800 at 300dpi</h6>
											<a href="#" className="btn">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="17.031"
													height="17.031"
													viewBox="0 0 17.031 17.031"
												>
													<g
														id="Icon_feather-download"
														data-name="Icon feather-download"
														transform="translate(1 1)"
													>
														<path
															id="Path_8715"
															data-name="Path 8715"
															d="M19.531,22.5v3.34a1.67,1.67,0,0,1-1.67,1.67H6.17A1.67,1.67,0,0,1,4.5,25.84V22.5"
															transform="translate(-4.5 -12.479)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_8716"
															data-name="Path 8716"
															d="M10.5,15l4.175,4.175L18.851,15"
															transform="translate(-7.16 -9.155)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_8717"
															data-name="Path 8717"
															d="M18,14.521V4.5"
															transform="translate(-10.484 -4.5)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
													</g>
												</svg>

												<span>Download</span>
											</a>
										</div>
									</div>
									<div className="beach-card-wrapper">
										<div className="beach-img-wrapper">
											<figure>
												<img src={thankyouimg} alt="" />
											</figure>
										</div>
										<div className="bech-content-wrapper">
											<h5>Beach View Island</h5>
											<h4>$249 Large</h4>
											<h6>1200 x 800 at 300dpi</h6>
											<a href="#" className="btn">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="17.031"
													height="17.031"
													viewBox="0 0 17.031 17.031"
												>
													<g
														id="Icon_feather-download"
														data-name="Icon feather-download"
														transform="translate(1 1)"
													>
														<path
															id="Path_8715"
															data-name="Path 8715"
															d="M19.531,22.5v3.34a1.67,1.67,0,0,1-1.67,1.67H6.17A1.67,1.67,0,0,1,4.5,25.84V22.5"
															transform="translate(-4.5 -12.479)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_8716"
															data-name="Path 8716"
															d="M10.5,15l4.175,4.175L18.851,15"
															transform="translate(-7.16 -9.155)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_8717"
															data-name="Path 8717"
															d="M18,14.521V4.5"
															transform="translate(-10.484 -4.5)"
															fill="none"
															stroke="#fff"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
													</g>
												</svg>

												<span>Download</span>
											</a>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6"></div>
									<div className="col-lg-6">
										<div className="product-detail">
											<div className="item-total">
												<h4>
													<span className="property">Item Total :</span>
													<span className="value">$50</span>
												</h4>
												<h4>
													<span className="property">
														Shipment and Delivery :
													</span>
													<span className="value">-$5</span>
												</h4>
												<h4>
													<span className="property">Taxes Applied :</span>
													<span className="value">$0</span>
												</h4>
											</div>
											<div className="order-total">
												<h4>
													<span className="property">Order Total</span>
													<span className="value">$3025.00</span>
												</h4>
											</div>
										</div>
										<div className="button-group">
											<Link className="btn" to="/product-detail">
												View Purchased Item
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					{/* thank you 2 details ends here */}
				</div>
			</section>
			{/* Thank You End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Thankyou2;
