import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ArtistPageLinks = () => {
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {/* Artist Link starts here */}
      <div className="artist-page-links-wrapper">
        <ul className="artist-list-wapper">
          <li className="artist-item">
            <a
              title="Blogs"
              onClick={() => {
                setActive(0);
                navigate("/artist-corner");
              }}
              className={
                location?.pathname == "/artist-corner"
                  ? "artist-link active"
                  : "artist-link"
              }
            >
              Blogs
            </a>
          </li>
          <li className="artist-item">
            <a
              title="Code Of Conduct"
              onClick={() => {
                setActive(1);
                navigate("/artist-code-conduct");
              }}
              className={
                location?.pathname == "/artist-code-conduct"
                  ? "artist-link active"
                  : "artist-link"
              }
            >
              Code Of Conduct
            </a>
          </li>
          <li className="artist-item">
            <a
              title="News"
              onClick={() => {
                setActive(2);
                navigate("/artist-news");
              }}
              className={
                location?.pathname == "/artist-news"
                  ? "artist-link active"
                  : "artist-link"
              }
            >
              News
            </a>
          </li>
          <li className="artist-item">
            <a
              title="Contests & Events"
              onClick={() => {
                setActive(3);
                navigate("/artist-contest");
              }}
              className={
                location?.pathname == "/artist-contest"
                  ? "artist-link active"
                  : "artist-link"
              }
            >
              Contests & Events
            </a>
          </li>
          <li className="artist-item">
            <a
              title="Release Forms"
              onClick={() => {
                setActive(4);
                navigate("/artist-release-form");
              }}
              className={
                location?.pathname == "/artist-release-form"
                  ? "artist-link active"
                  : "artist-link"
              }
            >
              Release Forms
            </a>
          </li>
          <li className="artist-item">
            <a
              title="	Licensing Agreements"
              onClick={() => {
                setActive(5);
                navigate("/artist-licensing-agreement");
              }}
              className={
                location?.pathname == "/artist-licensing-agreement"
                  ? "artist-link active"
                  : "artist-link"
              }
            >
              Licensing Agreements
            </a>
          </li>
          <li className="artist-item">
            <a
              title="Resources"
              onClick={() => {
                setActive(6);
                navigate("/artist-masonry");
              }}
              className={
                location?.pathname == "/artist-masonry"
                  ? "artist-link active"
                  : "artist-link"
              }
            >
              Resources
            </a>
          </li>
          <li className="artist-item">
            <a
              title="Upload Guidelines"
              onClick={() => {
                setActive(6);
                navigate("/artist-upload-guide");
              }}
              className={
                location?.pathname == "/artist-upload-guide"
                  ? "artist-link active"
                  : "artist-link"
              }
            >
              Upload Guidelines
            </a>
          </li>
        </ul>
      </div>
      {/* Artist Link ends here */}
    </>
  );
};

export default ArtistPageLinks;
