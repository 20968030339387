import React from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/removal-content.css";
import { upload1, upload2, upload3, upload4 } from "../../constant";
const RemovalContent = () => {
	return (
		<>
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-6">
							<div className="request-removal-wrapper">
								<div className="removal-heading-wrapper">
									<h3>Request For Removal OF Content</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim{" "}
									</p>
								</div>
								<div className="request-form-wrapper">
									<form action="">
										<div className="form-group">
											<label htmlFor="">
												Contributor Name <span>*</span>
											</label>
											<input
												type="text"
												placeholder="Enter contributor name"
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Membership Number <span>*</span>
											</label>
											<input
												type="text"
												placeholder="Enter membership number"
												className="form-control"
											/>
										</div>
										<div className="form-group row">
											<div className="col-lg-6">
												<label htmlFor="">
													Asset Type <span>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter Asset Type"
													className="form-control"
												/>
											</div>
											<div className="col-lg-6">
												<label htmlFor="">
													Asset Id Number <span>*</span>
												</label>
												<input
													type="text"
													placeholder="Enter asset number"
													className="form-control"
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<h6>Why would you like us to remove this content?</h6>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check1"
													/>
													<label className="form-check-label" for="check1">
														It's not my best work
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check2"
													/>
													<label className="form-check-label" for="check2">
														The asset is no longer relevant
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check3"
													/>
													<label className="form-check-label" for="check3">
														I expected more revenue
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check4"
													/>
													<label className="form-check-label" for="check4">
														Terminating my membership
													</label>
												</div>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-10">
												<span className="will">
													“We will be sorry to see you go. Can you please take a
													moment to let us know why you are leaving, so that we
													can make some improvements?{" "}
												</span>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check5"
													/>
													<label className="form-check-label" for="check5">
														Not enough revenue
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check6"
													/>
													<label className="form-check-label" for="check6">
														Not happy with our service
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check7"
													/>
													<label className="form-check-label" for="check7">
														No longer want to license my work
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check8"
													/>
													<label className="form-check-label" for="check8">
														I prefer not to say
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check9"
													/>
													<label className="form-check-label" for="check9">
														Other
													</label>
												</div>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<span className="light">
													We value your opinion and hope that you can write a
													few words to elaborate or to provide additional
													feedback.
												</span>
												<textarea
													className="form-control"
													name=""
													placeholder="Please type your comments here (optional)"
													id=""
													cols="30"
													rows="5"
												></textarea>
											</div>
										</div>
										<div className="form-group">
											<div className="divider-wrapper">
												<div className="divider"></div>
											</div>
										</div>
										<div className="form-group">
											<div className="col-lg-12">
												<div className="btn-wrapper">
													<button type="submit" className="btn">
														Save & Proceed
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default RemovalContent;
