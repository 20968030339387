import React from "react";
import { star } from "../constant";

const ReviewForm = () => {
	return (
		<>
			<section className="review-form">
				<div className="container">
					<div className="heading_wrapper">
						<h2>Add a Review</h2>
					</div>
					<form action="" method="post">
						<div className="form_start mt-3">
							<div className="form-group mb-0">
								<label htmlFor="description">
									Your email address will not be published.Required field are
									marked <span>(*)</span>{" "}
								</label>
							</div>
							<div className="form-group">
								<label htmlFor="description">Your rating</label>
								<div className="d-flex gap-1 mt-3">
									<img src={star} alt="" />
									<img src={star} alt="" />
									<img src={star} alt="" />
									<img src={star} alt="" />
									<img src={star} alt="" />
								</div>
							</div>
							<div className="form-group">
								<label htmlFor="description">
									Your Review <span>*</span>
								</label>
								<textarea
									cols="30"
									rows="10"
									className="form-control"
									id="description"
								></textarea>
							</div>
							<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
								<div className="form-group">
									<label htmlFor="Name*">
										Name<span>*</span>
									</label>
									<input type="text" className="form-control" id="Name*" />
								</div>
								<div className="form-group">
									<label htmlFor="Email">
										Email<span>*</span>
									</label>
									<input type="email" className="form-control" id="Email" />
								</div>
								<div className="submit-btn">
									<button className="btn mt-3">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	);
};
export default ReviewForm;
