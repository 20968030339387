import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { blogContestimg, BlogImg1 } from "../../constant";
import "../../assets/css/artist-contest.css";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const ArtistContest = () => {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 490,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};
	const news = [
		{
			id: 1,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 2,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 3,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 4,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 5,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 6,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
	];
	return (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}

			{/* artist contest Sec Start Here */}
			<section className="blog_sec artist-news-sec">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>Contests & Events</h1>
					</div>
					<div className="row mt-5">
						<div className="col-lg-8">
							<div className="blog-box-wrapper">
								<div className="blog-img-wrapper">
									<figure>
										<Link to="/artist-content-detail">
											<img src={blogContestimg} className="img-fluid" alt="" />
										</Link>
									</figure>
								</div>
								<div className="blog-content-wrapper">
									<h4>Discover High Quality, Stock Photos And Vectors</h4>
									<p>
										Etiam ultricies nisi vel augue. Curabitur ullamcorper
										ultricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
										natoque penatibus et magnis dis parturient montes,ricies
										nisi. Nam s. Cum sociis natoque penatibus et magnis dis
										pricies nisi. Nam eget dui. Etiam rhoncus. Cum sociis
										natoque penatibus et magnis dis p
									</p>
									<a href="/artist-content-detail">Learn More...</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="side-blog-wrapper">
								<div className="blog-box-wrapper">
									<div className="blog-content-wrapper">
										<h4>Discover High Quality, Stock Photos And Vectors</h4>
										<p>
											Etiam ultricies nisi vel augue. Curabitur ullamcorper
											ultricies nisi. Nam eget dui. Etiam rhoncus.
										</p>
										<a href="/artist-content-detail">Learn More...</a>
									</div>
								</div>
								<div className="blog-box-wrapper">
									<div className="blog-content-wrapper">
										<h4>Discover High Quality, Stock Photos And Vectors</h4>
										<p>
											Etiam ultricies nisi vel augue. Curabitur ullamcorper
											ultricies nisi. Nam eget dui. Etiam rhoncus.
										</p>
										<a href="/artist-content-detail">Learn More...</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<Slider {...settings}>
							{news.map((item, index) => {
								return (
									<>
										<div
											key={index}
											className="blog-slide-row col-lg-11 text-center"
										>
											<div className="blog_box">
												<div className="blog-img">
													<figure>
														<img
															src={item?.img}
															alt={item?.name}
															className="img-fluid"
														/>
													</figure>
													{/* <div className="date">
                            <h4>
                              02 <span>Mar</span>
                            </h4>
                          </div> */}
												</div>
												<div className="blog-detail">
													<div className="blog-description">
														<h2>{item?.name}</h2>
														<p>{item?.des}</p>
														<a href="/artist-content-detail">Learn More...</a>
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})}
						</Slider>
					</div>
				</div>
			</section>
			{/* artist contest Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistContest;
