import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/pricing.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
const Pricing = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header Start Here */}
			<section className="pricing-section">
				<div className="container">
					<div className="pricing-start">
						{/* This is Top Heading */}
						<div className="pricing-top-text">
							<div className="headings">
								<h1 className="mb-0">Pricing</h1>
								<h4>Subscriptions</h4>
							</div>
							<div className="register-option">
								<Link to="/register-buyer">
									<h4>Register Now</h4>
								</Link>
								<span>And Get 12 Free Images</span>
							</div>
						</div>
						{/* Here Pricing data Start */}
						<div className="pricingdata">
							<div className="thetable">
								<div className="row mx-0">
									<div className="col-lg-3 col-3 mt-3 px-2 pe-0">
										<div className="pricing-box simple">
											<div className="data-heading">
												<h4>Description Of Package</h4>
											</div>
											<div className="pricing-list">
												<div className="single-price">
													<span>Month to Month ( no contract)</span>
												</div>
												<div className="single-price">
													<span>Annual Commitment (Billed Monthly)</span>
												</div>
												<div className="single-price">
													<span>Annual Commitment (Billed Upfront)</span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-3 mt-3 px-2 ps-0">
										<div className="pricing-box blue">
											<div className="data-heading">
												<h6>
													12 Standard <br /> (any size)
												</h6>
											</div>
											<div className="pricing-list">
												<div className="single-price">
													<span>$69.00</span>
												</div>
												<div className="single-price">
													<span>$508.00</span>
												</div>
												<div className="single-price">
													<span>$459.00</span>
												</div>
											</div>
											<div className="buy-now mt-4">
												<Link to="/cart" className="btn">
													Buy Now
												</Link>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-3 mt-3 px-2">
										<div className="pricing-box blue">
											<div className="data-heading">
												<h6>
													25 Standard images <br />
													(any size) or 3 HD videos
												</h6>
											</div>
											<div className="pricing-list">
												<div className="single-price">
													<span>$69.00</span>
												</div>
												<div className="single-price">
													<span>$508.00</span>
												</div>
												<div className="single-price">
													<span>$459.00</span>
												</div>
											</div>
											<div className="buy-now mt-4">
												<Link to="/cart" className="btn">
													Buy Now
												</Link>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-3 mt-3 px-2">
										<div className="pricing-box blue">
											<div className="data-heading">
												<h6 className="riban-best mb-0">BEST VALUE</h6>
												<h6>
													25 Standard images <br />
													(any size) or 3 HD videos
												</h6>
											</div>
											<div className="pricing-list">
												<div className="single-price">
													<span>$69.00</span>
												</div>
												<div className="single-price">
													<span>$508.00</span>
												</div>
												<div className="single-price">
													<span>$459.00</span>
												</div>
											</div>
											<div className="buy-now mt-4">
												<Link to="/cart" className="btn">
													Buy Now
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="pricing-rules">
							<div className="row">
								<div className="col-lg-9 col-md-12 ms-auto">
									<div className="points-start">
										<p>
											> To make any purchases, the user must be signed up as a
											member (sign up is absolutely free)
										</p>
										<p>
											> Upon signing up, the user is entitled to 12 free
											standard images (to be redeemed within 12 months from date
											of sign-up)
										</p>
										<p>
											> Refunds will be credited to the user's account to be
											applied against future in-store purchases
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="terms-condition">
							<h3>Terms And Conditions</h3>
							<div className="text">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Integer feugiat scelerisque varius morbi enim nunc faucibus a.
									Volutpat maecenas volutpat blandit aliquam etiam erat velit.{" "}
								</p>
								<p>
									Tortor posuere ac ut consequat semper viverra. Vitae aliquet
									nec ullamcorper sit amet risus nullam eget. Massa sed
									elementum tempus egestas sed sed risus pretium.Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do{" "}
								</p>
								<p>
									eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Integer feugiat scelerisque varius morbi enim nunc faucibus a.
									Volutpat maecenas volutpat blandit aliquam etiam erat velit.{" "}
								</p>
							</div>
						</div>
						<div className="pricingdata">
							<div className="thetable">
								<div className="row mx-0">
									<div className="col-lg-3 col-3 px-2 pe-lg-0">
										<div className="data-heading pt-0">
											<h2 className="mb-0">Singles Pricing</h2>
											<h4>PRODUCT TYPE</h4>
										</div>
									</div>
									<div className="col-lg-3 col-3 mt-3 px-2 ps-lg-0">
										<div className="pricing-box blue">
											<div className="data-heading">
												<h6>
													25 Standard images <br />
													(any size) or 3 HD videos
												</h6>
											</div>
											<div className="pricing-list">
												<div className="single-price">
													<span>$69.00</span>
												</div>
												<div className="single-price">
													<span>$508.00</span>
												</div>
												<div className="single-price">
													<span>$459.00</span>
												</div>
											</div>
											<div className="buy-now mt-4">
												<Link to="/cart" className="btn">
													Buy Now
												</Link>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-3 mt-3 px-2">
										<div className="pricing-box blue">
											<div className="data-heading">
												<h6>
													25 Standard images <br />
													(any size) or 3 HD videos
												</h6>
											</div>
											<div className="pricing-list">
												<div className="single-price">
													<span>$69.00</span>
												</div>
												<div className="single-price">
													<span>$508.00</span>
												</div>
												<div className="single-price">
													<span>$459.00</span>
												</div>
											</div>
											<div className="buy-now mt-4">
												<Link to="/cart" className="btn">
													Buy Now
												</Link>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-3 mt-3 px-2">
										<div className="pricing-box blue">
											<div className="data-heading">
												{/* <h6 className='riban-best mb-0'>BEST VALUE</h6> */}
												<h6>
													12 Standard <br /> (any size)
												</h6>
											</div>
											<div className="pricing-list">
												<div className="single-price">
													<span>$69.00</span>
												</div>
												<div className="single-price">
													<span>$508.00</span>
												</div>
												<div className="single-price">
													<span>$459.00</span>
												</div>
											</div>
											<div className="buy-now mt-4">
												<Link to="/cart" className="btn">
													Buy Now
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="terms-condition">
							<h3>Terms And Conditions</h3>
							<div className="text">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Integer feugiat scelerisque varius morbi enim nunc faucibus a.
									Volutpat maecenas volutpat blandit aliquam etiam erat velit.{" "}
								</p>
								<p>
									Tortor posuere ac ut consequat semper viverra. Vitae aliquet
									nec ullamcorper sit amet risus nullam eget. Massa sed
									elementum tempus egestas sed sed risus pretium.Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do{" "}
								</p>
								<p>
									eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Integer feugiat scelerisque varius morbi enim nunc faucibus a.
									Volutpat maecenas volutpat blandit aliquam etiam erat velit.{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer Start Here */}
			<Footer />
			{/* Footer Start Here */}
		</>
	);
};
export default Pricing;
