import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/upload.css";
// Images Import Here
import { clip } from "../../constant";
const fileTypes = ["JPG", "PNG", "GIF"];
const Upload1 = () => {
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header Start Here */}
			<section className="uploadimage">
				<div className="container">
					<div className="testimonial_start">
						<div className="col-lg-8 mx-auto">
							<div className="form_start">
								<div className="top_text text-center">
									<h3>Upload Image / Videos</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									</p>
								</div>
								<form method="post">
									<div className="form-group">
										<label htmlFor="title">
											Title<span>*</span>
										</label>
										<input
											type="text"
											className="form-control"
											id="title"
											placeholder="Enter title"
										/>
									</div>
									<div className="form-group">
										<label htmlFor="description">
											Description<span>*</span>
										</label>
										<input
											type="text"
											className="form-control"
											id="description"
											placeholder="Enter description here"
										/>
									</div>
									<div className="form-group">
										<label htmlFor="location">
											Location <span>*</span>
										</label>
										<input
											type="text"
											className="form-control"
											id="location"
											placeholder="Country / State / City"
										/>
									</div>
									<div className="form-group">
										<label htmlFor="image">
											Image Capture Date<span>*</span>
										</label>
										<input
											type="text"
											className="form-control"
											id="image"
											placeholder="Enter Date"
										/>
									</div>
									<div className="form-group">
										<label htmlFor="Type">
											Product Type<span>*</span>
										</label>
										<select className="form-control" id="Type">
											<option value="Photo">Photo</option>
											<option value="Illustrations">Illustrations</option>
											<option value="Backgrounds">Backgrounds</option>
											<option value="Vectors">Vectors</option>
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="Category">
											Category<span>*</span>
										</label>
										<select className="form-control" id="Category">
											<option value="Photo">Photo</option>
											<option value="Video">Video</option>
											<option value="Illustrations">Illustrations</option>
											<option value="Backgrounds">Backgrounds</option>
											<option value="Other">Other</option>
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="tags">
											Tags<span>*</span>
										</label>
										<input
											type="text"
											className="form-control"
											id="tags"
											placeholder="Enter tags"
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">
											Selected Tags<span>*</span>
										</label>
										<div className="tags-flex">
											<button type="button" className="single_tag">
												<span className="tag-name">Accessories</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Fashion</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Lifestyle</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Outdoors & Sunset</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Lifestyle</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Outdoors & Sunset</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Accessories</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Fashion</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Lifestyle</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Outdoors & Sunset</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Lifestyle</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Outdoors & Sunset</span>
												<span className="cross">x</span>
											</button>
										</div>
									</div>
									<div className="form-group">
										<div className="upload-form">
											<label htmlFor="">
												Upload Release Forms<span>*</span>
											</label>
											<Link to="" className="learn-more">
												Learn More About Release Forms
											</Link>
										</div>
										<div className="include-people">
											<span className="first">
												If your image contain include people
											</span>
											<div className="checkboxes">
												<div className="form-check">
													<label className="form-check-label" for="check1">
														Yes
													</label>
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="check1"
													/>
												</div>
												<div className="form-check">
													<label className="form-check-label" for="no">
														no
													</label>
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="no"
													/>
												</div>
											</div>
											<span>You must upload signed model release form </span>
										</div>
										<div className="include-people mt-2 mt-lg-3">
											<span className="first">
												if your image contain include property{" "}
											</span>
											<div className="checkboxes">
												<div className="form-check">
													<label className="form-check-label" for="yes2">
														Yes
													</label>
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="yes2"
													/>
												</div>
												<div className="form-check">
													<label className="form-check-label" for="no3">
														Yes
													</label>
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="no3"
													/>
												</div>
											</div>
											<span>You must upload a property release form</span>
										</div>
									</div>
									<div className="form-group">
										<div className="file_upload_system">
											<label htmlFor="">
												Upload Release Form<span>*</span>
											</label>
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
											/>
											<figure className="clip_img">
												<img src={clip} alt="" />
											</figure>
										</div>
										<div className="tags-flex">
											<button type="button" className="single_tag">
												<span className="tag-name">Releas Form.pdf</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Releas Form.pdf</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Releas Form.pdf</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Releas Form.pdf</span>
												<span className="cross">x</span>
											</button>
										</div>
									</div>
									<div className="form-group">
										<div className="file_upload_system">
											<label htmlFor="">
												Upload Release Form<span>*</span>
											</label>
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
											/>
											<figure className="clip_img">
												<img src={clip} alt="" />
											</figure>
										</div>
										<div className="tags-flex">
											<button type="button" className="single_tag">
												<span className="tag-name">Releas Form.pdf</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Releas Form.pdf</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Releas Form.pdf</span>
												<span className="cross">x</span>
											</button>
											<button type="button" className="single_tag">
												<span className="tag-name">Releas Form.pdf</span>
												<span className="cross">x</span>
											</button>
										</div>
									</div>
									<ul className="terms-lines">
										<li>
											All Images and Videos are subject to approval by Moonscape
											sweet imagery
										</li>
										<li>
											Approval time may very based on the volume of submission
										</li>
										<li>
											Please review our <Link to="">Terms and Conditions</Link>{" "}
											for more information
										</li>
									</ul>
									<div className="form-group">
										<div className="form-submit-terms">
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="terms"
												/>
												<label
													className="form-check-label"
													for="terms"
													name="terms"
												>
													I have read and agree to Terms and Conditions
												</label>
											</div>
											<button className="btn submit_upload_form" type="submit">
												Upload
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer Start Here */}
			<Footer />
			{/* Footer Start Here */}
		</>
	);
};
export default Upload1;
