import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-recourses.css";
import {
	aboutpageimg,
	angleimg,
	frame1,
	frame2,
	framevideoimg,
	recoursecheckimg,
} from "../../constant";

const ArtistResources = () => {
	return (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}
			{/* recources Sec Start Here */}
			<section className="recourses-sec ">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="recourse-card-wrapper">
								<div className="recourse-content-wrapper">
									<h2>Getting The Most Out Of Night Photography</h2>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad mi
									</p>
								</div>
								<div className="about-img-wrapper border-line-left">
									<figure>
										<img src={aboutpageimg} className="img-fluid" alt="" />
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* recources Sec End Here */}
			{/* our mission starts here */}
			<section className="our-mission pt-5  mt-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper pe-2">
								<h2>Check Your Settings</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat.
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left">
								<figure>
									<img src={recoursecheckimg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* Look sec starts here */}
			<section className="look-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="look-content-wrapper">
								<h2>Look For The Perfect Frame</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
							<div className="look-content-wrapper">
								<h2>Look For The Perfect Frame</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Look sec ends here */}
			{/* our mission starts here */}
			<section className="our-mission pt-lg-5  mt-lg-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left pe-lg-5">
								<figure>
									<img src={frame1} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left pe-lg-5">
								<figure>
									<img src={frame2} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* Look sec starts here */}
			<section className="look-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="look-content-wrapper">
								<h2>Look For The Perfect Frame</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
							<div className="look-content-wrapper">
								<h2>Look For The Perfect Frame</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
							<div className="look-content-wrapper">
								<h2>What Do Professionals Have to Say?</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
							<div className="look-single-line-wrapper">
								<p className="text-center">
									You Can Read The Full Article Here{" "}
									<span>
										<a
											href="/blog-detail"
											style={{ color: "var(--extra-color-2)" }}
										>
											nim veniam, quis nostrud exercitation ullamco laboris nisi
											ut aliquip ex ea commodo consequat.
										</a>
									</span>
								</p>
							</div>
							<div className="look-content-wrapper">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Look sec ends here */}
			{/* recources Sec Start Here */}
			<section className="recourses-sec pt-0 pb-lg-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="recourse-card-wrapper">
								<div className="about-img-wrapper border-line-left">
									<figure>
										<img src={angleimg} className="img-fluid" alt="" />
										<img
											src={framevideoimg}
											className="frame-img"
											alt=""
											srcSet=""
										/>
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="recourse-content-wrapper mt-5 pt-5">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* recources Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistResources;
