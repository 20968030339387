import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ProfileHeader from "../../components/ProfileHeader";
import ProfileSidebar from "../../components/ProfileSidebar";
import "../../assets/css/myaccount.css";
import { paypal, stripe } from "../../constant";

const BillingInformation = () => {
	const [editBilling, seteditBilling] = useState(false);
	const handleBilling = () => {
		seteditBilling(true);
	};
	return (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header Ends here */}
			{/* Profile Header starts here */}
			<ProfileHeader />
			{/* Profile Header Ends here */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ProfileSidebar />
						</div>
						<div className="col-lg-9">
							<div className="my-account-wrapper">
								<div className="my-account-heading-wrapper">
									<h3>Billing Information</h3>
									<button onClick={handleBilling} className="btn">
										Edit Billing
									</button>
								</div>
								<div className="my-account-form-wrapper">
									<form action="">
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													First & last name <span>*</span>
												</label>
												{editBilling ? (
													<input
														type="text"
														placeholder="John Doe"
														className="edit"
													/>
												) : (
													<input type="text" placeholder="John Doe" readOnly />
												)}
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Last Name <span>*</span>
												</label>
												{editBilling ? (
													<input
														type="text"
														placeholder="John Doe@example.com"
														className="edit"
													/>
												) : (
													<input
														type="text"
														placeholder="John Doe@example.com"
														readOnly
													/>
												)}
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													Email <span>*</span>
												</label>
												<div className="pass-btn-field-wrapper">
													{editBilling ? (
														<input
															type="email"
															placeholder="John Doe@example.com"
															className="edit"
														/>
													) : (
														<input
															type="email"
															placeholder="John Doe@example.com"
															readOnly
														/>
													)}
												</div>
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Phone <span>*</span>
												</label>
												{editBilling ? (
													<input
														type="text"
														placeholder="123258478"
														className="edit"
													/>
												) : (
													<input type="text" placeholder="123258478" readOnly />
												)}
											</div>
											<div className="col-lg-4"></div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<label htmlFor="">
													Address <span>*</span>
												</label>
												{editBilling ? (
													<input
														type="text"
														placeholder="4787 White Oak Drive Kansas City, MO 66215"
														className="edit"
													/>
												) : (
													<input
														type="text"
														placeholder="4787 White Oak Drive Kansas City, MO 66215"
														readOnly
													/>
												)}
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<label htmlFor="">
													Suite , Appartment <span>*</span>
												</label>
												{editBilling ? (
													<input
														type="text"
														placeholder="4787 White Oak Drive Kansas City, MO 66215"
														className="edit"
													/>
												) : (
													<input
														type="text"
														placeholder="4787 White Oak Drive Kansas City, MO 66215"
														readOnly
													/>
												)}
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													City <span>*</span>
												</label>
												{editBilling ? (
													<input
														type="text"
														placeholder="4787 White Oak Drive Kansas City, MO 66215"
														className="edit"
													/>
												) : (
													<input
														type="text"
														readOnly
														placeholder="4787 White Oak Drive Kansas City, MO 66215"
													/>
												)}
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Country <span>*</span>
												</label>
												{editBilling ? (
													<input
														type="text"
														placeholder="United States"
														className="edit"
													/>
												) : (
													<input
														type="text"
														placeholder="United States"
														readOnly
													/>
												)}
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Zipcode <span>*</span>
												</label>
												{editBilling ? (
													<input
														type="number"
														className="edit"
														placeholder="66215"
													/>
												) : (
													<input type="password" placeholder="66215" readOnly />
												)}
											</div>
										</div>
										{editBilling ? (
											<div className="form-group">
												{" "}
												<button
													type="button"
													onClick={() => seteditBilling(false)}
													className="btn"
												>
													Save
												</button>{" "}
											</div>
										) : (
											""
										)}
									</form>
								</div>
								<div className="my-account-heading-wrapper">
									<h3>Payment Information</h3>
								</div>
								<div className="my-account-form-wrapper">
									<form action="">
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													Paypal Email <span>*</span>
												</label>
												<input type="text" placeholder="John Doe@example.com" />
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Paypal Password <span>*</span>
												</label>
												<input type="text" placeholder="John Doe548" />
											</div>
											<div className="col-lg-4">
												<div className="img-wrapper text-center">
													<img src={paypal} className="img-fluid" alt="" />
												</div>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													Stripe Email <span>*</span>
												</label>
												<input type="text" placeholder="John Doe@example.com" />
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Stripe Password <span>*</span>
												</label>
												<input type="text" placeholder="John Doe548" />
											</div>
											<div className="col-lg-4">
												<div className="img-wrapper text-center">
													<img src={stripe} className="img-fluid" alt="" />
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer Ends here */}
		</>
	);
};

export default BillingInformation;
