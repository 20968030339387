import React from "react";
import { Link } from "react-router-dom";
import {
	footerFb,
	footerIn,
	footerTw,
	footeryoutube,
	linkedin,
	logo,
} from "../constant";

const Footer = () => {
	const d = new Date();
	let year = d.getFullYear();
	return (
		<>
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="footer-widget-wrapper">
								<div className="footer-logo-wrapper">
									<a href="/">
										<img src={logo} className="img-fluid" alt="" />
									</a>
								</div>
								<div className="footer-content-wrapper">
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
										sollii sn erat vel n erat n erat vel rhoncus n erat vel
										rhoncus sollicitudin.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="footer-2-widget-wrapper">
								<div className="footer-heading">
									<h5>Quick Links</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										<li>
											<a href="/faq">
												{" "}
												<i className="fa fa-angle-right"></i> <span>Faq</span>
											</a>
										</li>
										<li>
											<a href="/blogs">
												<i className="fa fa-angle-right"></i> <span>Blog</span>
											</a>
										</li>
										<li>
											<Link to="/testimonial">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Testimonials</span>
											</Link>
										</li>
										<li>
											<Link to="/contact">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Contact Us</span>
											</Link>
										</li>
										<li>
											<Link to="/help">
												<i className="fa fa-angle-right"></i>{" "}
												<span>How Can We Help?</span>
											</Link>
										</li>
										<li>
											<Link to="/sitemap">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Sitemap</span>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="footer-2-widget-wrapper">
								<div className="footer-heading">
									<h5>Legal</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										<li>
											<a href="/website-term">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Website Terms of Use</span>
											</a>
										</li>
										<li>
											<a href="/terms">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Terms Of Service</span>
											</a>
										</li>
										<li>
											<a href="/Policy">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Privacy Policy</span>
											</a>
										</li>
										<li>
											<a href="/agreement">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Contributor Agreement</span>
											</a>
										</li>
										<li>
											<Link to="/LicenseAgreement">
												<i className="fa fa-angle-right"></i>{" "}
												<span>License Agreement</span>
											</Link>
										</li>
										<li>
											<a href="/copyright">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Copyright</span>
											</a>
										</li>
										<li>
											<a href="/release-form">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Release Forms</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							{" "}
							<div className="footer-2-widget-wrapper">
								<div className="footer-heading">
									<h5>Learn More</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										<li>
											<a href="/pricing">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Subscription Info</span>
											</a>
										</li>
										<li>
											<a href="/register-contributor">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Sell Your Work</span>
											</a>
										</li>
										<li>
											<a href="/tips">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Tips and Tricks For Creatives</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="footer-2-widget-wrapper">
								<div className="footer-heading">
									<h5>Social Media</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul className="social-icon">
										<li>
											<a href="#">
												<img src={footerFb} className="img-fluid" alt="" />
											</a>
										</li>
										<li>
											<a href="#">
												<img src={footerIn} className="img-fluid" alt="" />
											</a>
										</li>
										<li>
											<a href="#">
												<img src={linkedin} className="img-fluid" alt="" />
											</a>
										</li>
										<li>
											<a href="#">
												<img src={footeryoutube} className="img-fluid" alt="" />
											</a>
										</li>
									</ul>
								</div>
								<div className="footer-heading">
									<h5>Contact Info</h5>
								</div>
								<div className="email-wrapper">
									<a href="mailto:info@moonscapesuite.com">
										info@moonscapesuite.com
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* COPYRIGHT starts here */}
			<div id="copyright">
				<div className="container">
					<div className="row">
						<div className="col-lg-9">
							<div className="copyright-text-wrapper text-center">
								<p>© Copyright MOONSCAPE {year}. All Right Reserved.</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="footer-menu-wrapper">
								<ul>
									<li>
										<Link to="/Policy">PRIVACY POLICY</Link>
									</li>
									<li>
										<Link to="/terms">TERMS & CONDITIONS</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* COPYRIGHT ends here */}
		</>
	);
};

export default Footer;
