import React from "react";
import {
  similar1,
  similar2,
  similar3,
  similar4,
  similar5,
  similar6,
  similar7,
  similar8,
} from "../constant";

const SimilarVideo = () => {
  const SimilarImages = [
    {
      id: 1,
      imageName: similar1,
    },
    {
      id: 2,
      imageName: similar2,
    },
    {
      id: 3,
      imageName: similar3,
    },
    {
      id: 4,
      imageName: similar4,
    },
    {
      id: 5,
      imageName: similar5,
    },
    {
      id: 6,
      imageName: similar6,
    },
    {
      id: 7,
      imageName: similar7,
    },
    {
      id: 8,
      imageName: similar8,
    },
  ];
  return (
    <>
      <section className="similarimages">
        <div className="container">
          <div className="similarstart">
            <div className="heading_wrapper">
              <h2>Trending Videos</h2>
            </div>
          </div>
          <div className="imageslist">
            <div className="row">
              {SimilarImages?.map((data, index) => (
                <div className="col-md-3 mt-3" key={index}>
                  <figure>
                    <img src={data?.imageName} alt="" className="img-fluid" />
                    <div className="video-timer">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7.5"
                          height="8.75"
                          viewBox="0 0 7.5 8.75"
                        >
                          <path
                            id="Polygon_11"
                            data-name="Polygon 11"
                            d="M3.511,1.481a1,1,0,0,1,1.728,0L7.873,6a1,1,0,0,1-.864,1.5H1.741A1,1,0,0,1,.877,6Z"
                            transform="translate(7.5) rotate(90)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </div>
                      <h4 className="time">0:20</h4>
                    </div>
                  </figure>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SimilarVideo;
