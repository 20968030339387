import React, { useEffect, useState } from "react";
import { adminImg, langUsa, logo } from "../constant";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import { Link, useLocation } from "react-router-dom";
import GoTop from "./GoTop";

const Header = () => {
	const [canvas, setCanvas] = useState();
	const [search, setSearch] = useState(false);
	const [filter, setFilter] = useState(false);
	const [background, setBackground] = useState("");
	const [hexa, setHexa] = useState(background);

	const [picker, setPicker] = useState(false);
	const toggleChecked = () => setPicker((value) => !value);

	const saveColors = (color) => {
		setBackground(color.hex);
		setHexa(color.hex);
	};

	const saveColors2 = (e) => {
		setHexa(e.target.value);
		setBackground(e.target.value);
	};

	const location = useLocation();
	const currentLocation = window.location.pathname;

	return (
		<>
			<GoTop />
			{/* Header starts here */}
			<header
				id="top"
				className={currentLocation == "/" ? "home-page" : "inner-page"}
			>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-2 col-4">
							<div className="logo-wrapper">
								<Link to="/">
									<figure>
										<img src={logo} className="img-fluid" alt={logo} />
									</figure>
								</Link>
							</div>
						</div>
						<div className="col-lg-7 col-1">
							<div className="header-menu-wrapper">
								<ul className="navbar-nav flex-row justify-content-around">
									<li className="nav-item">
										<Link
											to="/"
											className={
												currentLocation == "/" ? "nav-link active" : "nav-link"
											}
										>
											<span>Home</span>
											<span>Home</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/images"
											className={
												currentLocation == "/images"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>IMAGES</span>
											<span>IMAGES</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/videos"
											className={
												currentLocation == "/videos"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>VIDEOS</span>
											<span>VIDEOS</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/about"
											className={
												currentLocation == "/about"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>ABOUT</span>
											<span>ABOUT</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/contributors"
											className={
												currentLocation == "/contributors"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>CONTRIBUTORS</span>
											<span>CONTRIBUTORS</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/artist-corner"
											className={
												currentLocation == "/artist-corner"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>ARTIST'S CORNER</span>
											<span>ARTIST'S CORNER</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/pricing"
											className={
												currentLocation == "/pricing"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>PRICING</span>
											<span>PRICING</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/contact"
											className={
												currentLocation == "/contact"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>CONTACT</span>
											<span>CONTACT</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-7">
							<div className="header-icons-wrapper">
								<ul className="icon-menu">
									<li className="icon-item">
										<button
											onClick={() => setSearch(true)}
											className="icon-link search"
										>
											<i className="fa-solid fa-magnifying-glass"></i>
										</button>
									</li>
									<li className="icon-item for-responsive">
										<Link to="/cart2" className="icon-link">
											<i className="fa-solid fa-bag-shopping"></i>
										</Link>
									</li>
									<li className="icon-item for-responsive">
										<Link to="/myfavourite" className="icon-link">
											<i className="fa-regular fa-heart"></i>
										</Link>
									</li>
									<li className="nav-item dropdown for-responsive">
										<Link
											className="nav-link dropdown-toggle"
											to="#"
											id="navbarDropdown"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<img src={langUsa} alt="" />
											<span className="ms-2">ENG</span>
										</Link>
										<ul
											className="dropdown-menu"
											aria-labelledby="navbarDropdown"
										>
											<li>
												<Link className="dropdown-item" to="#">
													<img src={langUsa} alt="" />
													<span>ENG</span>
												</Link>
											</li>
										</ul>
									</li>
									<li className="nav-item dropdown profile-icon">
										<a
											className="nav-link dropdown-toggle"
											to="#"
											id="navbarDropdown"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<div className="wel-wrapper">
												<div className="welcome-sec">
													<span className="greeting">Welcome</span>

													<span className="name">John Doe</span>
												</div>
												<div className="admin-img">
													<Link to="/artist-account">
														<img src={adminImg} alt="" />
													</Link>
												</div>
											</div>
										</a>
										<ul
											className="dropdown-menu"
											aria-labelledby="navbarDropdown"
										>
											<li>
												<Link className="dropdown-item" to="/login">
													My Account
												</Link>
											</li>
											<li>
												<Link className="dropdown-item" to="#">
													Sign Out
												</Link>
											</li>
										</ul>
									</li>
								</ul>
								<div className="responsive-bar">
									<button onClick={() => setCanvas(true)}>
										<i className="fa-solid fa-bars"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className={search ? "search-filter show" : "search-filter"}>
						<form action="">
							<div className="search-box">
								<button
									className="filter"
									type="button"
									onClick={() => setFilter(true)}
								>
									<svg
										id="Component_17_7"
										data-name="Component 17 – 7"
										xmlns="http://www.w3.org/2000/svg"
										width="25"
										height="19.985"
										viewBox="0 0 25 19.985"
									>
										<g
											id="Group_8673"
											data-name="Group 8673"
											transform="translate(-1640 -474.03)"
										>
											<g
												id="Group_8672"
												data-name="Group 8672"
												transform="translate(848.01 68.424)"
											>
												<path
													id="Path_8719"
													data-name="Path 8719"
													d="M918.466,412.33v.349q0,5.9,0,11.8a2.05,2.05,0,0,1-.019.389.89.89,0,0,1-1.764-.139c0-.093,0-.186,0-.279q0-5.885.006-11.771a.408.408,0,0,0-.316-.473,3.4,3.4,0,1,1,2.435,0C918.7,412.245,918.6,412.282,918.466,412.33Zm.734-3.292a1.627,1.627,0,1,0-1.633,1.626A1.622,1.622,0,0,0,919.2,409.039Z"
													transform="translate(-113.083 0)"
												/>
												<path
													id="Path_8720"
													data-name="Path 8720"
													d="M1040.667,422.112c0,.91.007,1.82,0,2.73a.891.891,0,1,1-1.781.02c0-.836-.009-1.673,0-2.51,0-.2-.064-.265-.242-.325a3.394,3.394,0,0,1-.018-6.406.323.323,0,0,0,.26-.371q-.012-4.239,0-8.477a.89.89,0,0,1,1.188-.917.914.914,0,0,1,.6.916q0,2.189,0,4.378c0,1.376,0,2.751,0,4.127,0,.167.027.258.21.322a3.394,3.394,0,0,1-.011,6.436C1040.8,422.057,1040.742,422.082,1040.667,422.112Zm-2.523-3.311a1.628,1.628,0,1,0,1.638-1.621A1.621,1.621,0,0,0,1038.144,418.8Z"
													transform="translate(-226.192 -0.18)"
												/>
												<path
													id="Path_8721"
													data-name="Path 8721"
													d="M796.291,409.488c0,.93,0,1.86,0,2.79a.257.257,0,0,0,.206.3,3.395,3.395,0,0,1,0,6.435c-.187.065-.209.159-.208.323q.006,2.748,0,5.5a.886.886,0,0,1-1.236.871.915.915,0,0,1-.549-.92c0-1.795,0-3.59.006-5.384a.36.36,0,0,0-.279-.417,3.386,3.386,0,0,1,.023-6.381.325.325,0,0,0,.257-.375q-.013-2.72,0-5.44a.9.9,0,0,1,1.194-.917.885.885,0,0,1,.589.861C796.294,407.647,796.291,408.567,796.291,409.488Zm-.864,4.678a1.628,1.628,0,1,0,1.616,1.645A1.625,1.625,0,0,0,795.427,414.166Z"
													transform="translate(0 -0.188)"
												/>
											</g>
										</g>
									</svg>
								</button>
								<div className="form-group">
									<input
										type="text"
										placeholder="Search Artist, Artwork, Collection .."
										className="form-control"
									/>
									<button className="camera">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="21.497"
											height="19"
											viewBox="0 0 21.497 19"
										>
											<g
												id="Group_8989"
												data-name="Group 8989"
												transform="translate(-1791 454)"
												opacity="0.5"
											>
												<g
													id="Group_8988"
													data-name="Group 8988"
													transform="translate(1791 -454)"
												>
													<path
														id="Path_9814"
														data-name="Path 9814"
														d="M702.656,236.439q-3.937,0-7.874,0a2.726,2.726,0,0,1-2.724-1.888,3.142,3.142,0,0,1-.142-.942q-.015-4.944,0-9.888a2.735,2.735,0,0,1,2.794-2.783c.727,0,1.453-.008,2.179,0a.431.431,0,0,0,.451-.285c.268-.564.553-1.119.833-1.677a1.758,1.758,0,0,1,1.69-1.043c1.586,0,3.172,0,4.757,0a.756.756,0,0,1,.737.478.683.683,0,0,1-.194.808,1.126,1.126,0,0,1-.6.2c-1.539.017-3.078.013-4.617,0a.45.45,0,0,0-.474.286c-.27.571-.559,1.133-.844,1.7a1.738,1.738,0,0,1-1.656,1.02c-.75,0-1.5,0-2.25,0a1.236,1.236,0,0,0-1.312,1.3q0,4.944,0,9.888a1.238,1.238,0,0,0,1.313,1.307q7.932,0,15.865,0A1.242,1.242,0,0,0,711.9,233.6q0-4.112,0-8.224a1.8,1.8,0,0,1,.02-.326.744.744,0,0,1,1.474.1c.01.234,0,.469,0,.7q0,3.866,0,7.732a2.721,2.721,0,0,1-2.2,2.787,3.634,3.634,0,0,1-.745.064Q706.558,236.443,702.656,236.439Z"
														transform="translate(-691.908 -217.439)"
													/>
													<path
														id="Path_9815"
														data-name="Path 9815"
														d="M797.478,310.655a4.745,4.745,0,1,1-4.754-4.749A4.745,4.745,0,0,1,797.478,310.655Zm-1.5.016a3.245,3.245,0,1,0-3.258,3.237A3.249,3.249,0,0,0,795.978,310.671Z"
														transform="translate(-781.986 -299.911)"
													/>
													<path
														id="Path_9816"
														data-name="Path 9816"
														d="M951.449,211.958c.434,0,.846-.006,1.257,0a.754.754,0,0,1,.711.544.72.72,0,0,1-.309.817,1.031,1.031,0,0,1-.461.13c-.388.018-.777.006-1.2.006,0,.4,0,.79,0,1.177a.778.778,0,0,1-.275.643.7.7,0,0,1-.766.117.686.686,0,0,1-.453-.628c-.013-.343-.005-.686-.006-1.03,0-.084,0-.168,0-.28-.434,0-.845.008-1.255,0a.744.744,0,0,1-.667-1.08.721.721,0,0,1,.68-.416c.4,0,.809,0,1.246,0,0-.446-.008-.873,0-1.3a.715.715,0,0,1,.529-.669.706.706,0,0,1,.818.3,1.159,1.159,0,0,1,.141.506C951.463,211.171,951.449,211.552,951.449,211.958Z"
														transform="translate(-931.948 -209.958)"
													/>
												</g>
											</g>
										</svg>
									</button>
								</div>
								<div className="search-btn">
									<button
										type="button"
										className="search"
										onClick={() => setSearch(false)}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="27.725"
											height="27.706"
											viewBox="0 0 27.725 27.706"
										>
											<path
												id="Path_9860"
												data-name="Path 9860"
												d="M-64.233-245.773a11.009,11.009,0,0,1-9.182,1.261,10.83,10.83,0,0,1-6.032-4.461,11.091,11.091,0,0,1,1.375-13.983,11.09,11.09,0,0,1,13.98-1.41A10.82,10.82,0,0,1-59.37-257.4a10.955,10.955,0,0,1-1.5,8.26l2.708,2.168q1.642,1.313,3.284,2.625a3.255,3.255,0,0,1,1.087,3.714A3.244,3.244,0,0,1-57-238.511a3.2,3.2,0,0,1-2.464-1.3q-2.1-2.633-4.208-5.261C-63.853-245.3-64.04-245.532-64.233-245.773Zm-5.933-17.613a8.3,8.3,0,0,0-8.313,8.245,8.307,8.307,0,0,0,8.306,8.342,8.3,8.3,0,0,0,8.282-8.247A8.3,8.3,0,0,0-70.166-263.386Z"
												transform="translate(81.312 266.215)"
												fill="#fff"
											/>
										</svg>
									</button>
								</div>
							</div>
						</form>
						<div className={filter ? "flter-box show" : "flter-box"}>
							<div className="topbar">
								<button
									className="btn"
									type="button"
									onClick={() => setFilter(false)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="25"
										height="19.985"
										viewBox="0 0 25 19.985"
									>
										<g
											id="Group_8672"
											data-name="Group 8672"
											transform="translate(-791.99 -405.606)"
										>
											<path
												id="Path_8719"
												data-name="Path 8719"
												d="M918.466,412.33v.349q0,5.9,0,11.8a2.05,2.05,0,0,1-.019.389.89.89,0,0,1-1.764-.139c0-.093,0-.186,0-.279q0-5.885.006-11.771a.408.408,0,0,0-.316-.473,3.4,3.4,0,1,1,2.435,0C918.7,412.245,918.6,412.282,918.466,412.33Zm.734-3.292a1.627,1.627,0,1,0-1.633,1.626A1.622,1.622,0,0,0,919.2,409.039Z"
												transform="translate(-113.083 0)"
												fill="#fff"
											/>
											<path
												id="Path_8720"
												data-name="Path 8720"
												d="M1040.667,422.112c0,.91.007,1.82,0,2.73a.891.891,0,1,1-1.781.02c0-.836-.009-1.673,0-2.51,0-.2-.064-.265-.242-.325a3.394,3.394,0,0,1-.018-6.406.323.323,0,0,0,.26-.371q-.012-4.239,0-8.477a.89.89,0,0,1,1.188-.917.914.914,0,0,1,.6.916q0,2.189,0,4.378c0,1.376,0,2.751,0,4.127,0,.167.027.258.21.322a3.394,3.394,0,0,1-.011,6.436C1040.8,422.057,1040.742,422.082,1040.667,422.112Zm-2.523-3.311a1.628,1.628,0,1,0,1.638-1.621A1.621,1.621,0,0,0,1038.144,418.8Z"
												transform="translate(-226.192 -0.18)"
												fill="#fff"
											/>
											<path
												id="Path_8721"
												data-name="Path 8721"
												d="M796.291,409.488c0,.93,0,1.86,0,2.79a.257.257,0,0,0,.206.3,3.395,3.395,0,0,1,0,6.435c-.187.065-.209.159-.208.323q.006,2.748,0,5.5a.886.886,0,0,1-1.236.871.915.915,0,0,1-.549-.92c0-1.795,0-3.59.006-5.384a.36.36,0,0,0-.279-.417,3.386,3.386,0,0,1,.023-6.381.325.325,0,0,0,.257-.375q-.013-2.72,0-5.44a.9.9,0,0,1,1.194-.917.885.885,0,0,1,.589.861C796.294,407.647,796.291,408.567,796.291,409.488Zm-.864,4.678a1.628,1.628,0,1,0,1.616,1.645A1.625,1.625,0,0,0,795.427,414.166Z"
												transform="translate(0 -0.188)"
												fill="#fff"
											/>
										</g>
									</svg>
								</button>
								<h4>Filter</h4>
							</div>
							<div className="row">
								<div className="col-lg-6">
									<div className="radio-box">
										<div className="head">
											<h3>Categories</h3>
										</div>
										<ul className="list">
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="category_radio"
														checked
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Free</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="category_radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Premium</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="category_radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Standard</h4>
													</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="radio-box images-box right-side">
										<div className="head">
											<h3>Image / Video Type</h3>
										</div>
										<ul className="list">
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="image_radio"
														checked
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Image</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="image_radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Videos</h4>
													</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="radio-box">
										<div className="head">
											<h3>Trending Now</h3>
										</div>
										<ul className="list">
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
														checked="checked"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Camping</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Wildlife</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Laughter</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Color</h4>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="trending__radio"
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>Color</h4>
													</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="radio-box tags right-side">
										<div className="head">
											<h3>Tags</h3>
											<button className="clear">Clear All</button>
										</div>
										<ul>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Accessories</span>
												</button>
											</li>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Fashion</span>
												</button>
											</li>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Lifestyle</span>
												</button>
											</li>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Outdoors & Sunset</span>
												</button>
											</li>
											<li>
												<button>
													<span className="cut">x</span>
													<span className="name">Accessories</span>
												</button>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="radio-box colors">
										<div className="head">
											<h3>Color</h3>
										</div>
										<ul className="list">
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="color_radio"
														checked="checked"
													/>
													<span className="checkmark">
														<div
															className="dot red"
															style={{ backgroundColor: "#FF0000" }}
														></div>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="color_radio"
													/>
													<span className="checkmark">
														<div
															className="dot green"
															style={{ backgroundColor: "#31FF00" }}
														></div>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="color_radio"
													/>
													<span className="checkmark">
														<div
															className="dot blue"
															style={{ backgroundColor: "#00D3FF" }}
														></div>
													</span>
												</label>
											</li>
											<li>
												<label className="filter_container form-check-label">
													<input
														className="form-check-input"
														hidden
														type="radio"
														name="color_radio"
													/>
													<span className="checkmark">
														<div
															className="dot yellow"
															style={{ backgroundColor: "#DFFF00" }}
														></div>
													</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="radio-box color-picker right-side">
										<div className="head">
											<h3>Hex Code</h3>
										</div>
										<div className="form-group">
											<div className="clip-field">
												<input
													type="text"
													value={hexa}
													onChange={saveColors2}
													placeholder="Enter Hex or select "
													className="form-control"
												/>
												<button
													onClick={toggleChecked}
													className="background"
													style={{ backgroundColor: hexa }}
												></button>
											</div>
											<TwitterPicker
												className={picker ? "picker-show" : ""}
												onChangeComplete={saveColors}
												color={hexa}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			{/* Header ends here */}

			{/* Mobile Header Start Here */}
			<div className={canvas ? "mobile-header active" : "mobile-header"}>
				<div className="inner-box">
					<div className="cancel">
						<button onClick={() => setCanvas(false)}>
							<i className="fa-solid fa-xmark"></i>
						</button>
					</div>
					<ul className="menus">
						<li className="menu-items">
							<Link to="/" className="menu-link">
								Home
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/videos" className="menu-link">
								Images
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/videos" className="menu-link">
								Videos
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/about" className="menu-link">
								About
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/contributors" className="menu-link">
								Contributors
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/artist-corner" className="menu-link">
								Artist corner
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/pricing" className="menu-link">
								Pricing
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/contact" className="menu-link">
								Contact us
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/login" className="menu-link">
								Login
							</Link>
						</li>
						<li className="menu-items">
							<Link to="/register-page" className="menu-link">
								Register
							</Link>
						</li>
					</ul>
					<div className="action-btns">
						<ul>
							<li>
								<Link to="/cart2">
									<i className="fa-solid fa-bag-shopping"></i>
								</Link>
							</li>
							<li>
								<Link to="/myfavourite">
									<i className="fa-regular fa-heart"></i>
								</Link>
							</li>
						</ul>
					</div>
					<div className="language-tarnslator">
						<li className="nav-item dropdown">
							<a
								className="nav-link dropdown-toggle"
								to="#"
								id="navbarDropdown"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={langUsa} alt="" />
								<span className="ms-2">ENG</span>
							</a>
							<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
								<li>
									<a className="dropdown-item" to="#">
										<img src={langUsa} alt="" />
										<span>ENG</span>
									</a>
								</li>
							</ul>
						</li>
					</div>
				</div>
			</div>
			{/* Mobile Header End Here */}
		</>
	);
};

export default Header;
