import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/contributors.css";
import {
	aboutpageimg,
	community,
	con1,
	con2,
	con3,
	con4,
	create,
	earning,
	moneyimg,
	sell,
	upload,
} from "../../constant";
const Contributors = () => {
	return (
		<>
			<Header />
			{/* Contributors page header starts here */}
			<section className="about-page-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="about-page-header-content-wrapper">
								<h1>Contributors</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Contributors page header  ends here */}
			{/* what is contributors starts here */}
			<section className="what-contributors">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="cont-img-wrapper">
								<figure>
									<img src={con1} className="img-fluid" alt="" />
								</figure>
								<figure>
									<img src={con2} className="img-fluid" alt="" />
								</figure>
								<figure>
									<img src={con3} className="img-fluid" alt="" />
								</figure>
								<figure>
									<img src={con4} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="contributors-content-wrapper">
								<h2>What is Contributor</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor s
								</p>
								<div className="con-icons-wrapper">
									<ul>
										<li>
											<div className="icons-wrapper">
												<div className="icon">
													<img src={create} className="img-fluid" alt="" />
												</div>
												<div className="icon-content-wrapper">
													<h5>Create</h5>
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do
													</p>
												</div>
											</div>
										</li>
										<li>
											<div className="icons-wrapper">
												<div className="icon">
													<img src={upload} className="img-fluid" alt="" />
												</div>
												<div className="icon-content-wrapper">
													<h5>Upload</h5>
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do
													</p>
												</div>
											</div>
										</li>
										<li>
											<div className="icons-wrapper">
												<div className="icon">
													<img src={sell} className="img-fluid" alt="" />
												</div>
												<div className="icon-content-wrapper">
													<h5>Sell</h5>
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do
													</p>
												</div>
											</div>
										</li>
										<li>
											<div className="btn-wrapper">
												<a href="/register-contributor" className="btn">
													Become A Contributor
												</a>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* what is contributors ends here */}
			{/* Why contributors starts here  */}
			<section className="why-contribute">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8 text-center">
							<div className="why-contribute-heading-wrapper">
								<h3>Why Contribute To Moonscape Suite</h3>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-4">
							<div className="why-card-wrapper">
								<div className="why-img-wrapper">
									{/* <i className="fa-regular fa-pen-to-square"></i> */}
									<img src={community} className="img-fluid" alt="" />
								</div>
								<div className="why-content-wrapper">
									<h5>Lorem Ipsum</h5>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="why-card-wrapper">
								<div className="why-img-wrapper">
									<i className="fa-sharp fa-solid fa-images"></i>
								</div>
								<div className="why-content-wrapper ">
									<h5>Lorem Ipsum</h5>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="why-card-wrapper">
								<div className="why-img-wrapper">
									<img src={earning} className="img-fluid" alt="" />
								</div>
								<div className="why-content-wrapper">
									<h5>Lorem Ipsum</h5>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-12">
							<div className="btn-wrapper text-center">
								<a href="/register-contributor" className="btn">
									Let’s Get Started
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Why contributors ends here  */}
			{/* Become contributor  starts here */}
			<section className="become-con">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="become-heading-wrapper">
								<h3>
									How do you become a Contributor? - It’s easy and it’s free, in
									only 3 simple steps…{" "}
								</h3>
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-lg-12">
							<div className="steps-wrapper">
								<ul>
									<li>
										<div className="step-card-wrapper">
											<div className="step-card-img-wrapper">
												{/* <h3>
													1<sup>st</sup>
												</h3> */}
												<h3>1</h3>
											</div>
											<div className="step-content-wrapper">
												<h4>The first step is to sign up.</h4>
												<p>
													You can do this by clicking on any of the Sign Up
													links on this page. You will then be re-directed to an
													account request form, asked to answer a few simple
													questions and to create your security password.
													Signing up for an account is absolutely free with no
													strings attached.
												</p>
											</div>
										</div>
									</li>
									<li>
										<div className="step-card-wrapper">
											<div className="step-card-img-wrapper">
												<h3>2</h3>
											</div>
											<div className="step-content-wrapper">
												<h4>Create, create, and create some more. </h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim ad minim veniam, quis
													nostrud exercitation ullamco laboris nisi ut aliquip
													ex ea commodo consequat. Lorem ipsum dolor sit amet,
													consectetur adipiscing elit.
												</p>
											</div>
										</div>
									</li>
									<li>
										<div className="step-card-wrapper">
											<div className="step-card-img-wrapper">
												<h3>3</h3>
											</div>
											<div className="step-content-wrapper">
												<h4>Upload your creations. </h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim ad minim veniam, quis
													nostrud exercitation ullamco laboris nisi ut aliquip
													ex ea commodo consequat. Lorem ipsum dolor sit amet,
													consectetur adipiscing elit.
												</p>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Become contributor  ends here */}
			{/* our vision starts here */}
			<section className="our-vision con-vision">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>
									How much money will you make as a Contributor? - It’s all up
									to you…
								</h2>
								<p>
									As a Contributor, you can earn up to 45% commission when one
									of your digital images or videos is purchased at MSI Imagery.
									MSI pays you based on your current Contributor Level, which is
									based on how many of your images are sold at MSI in a given
									calendar year. The higher your level, the higher your
									commission, ranging from 10% at Galaxy 1 to 45% at Galaxy 7.
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-right">
								<figure>
									<img src={moneyimg} className="img-fluid w-100" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our vision ends here */}
			{/* Pricing starts here */}
			<section className="pricing-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="pricing-heading-wrapper">
								<h3>Pricing Structure</h3>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="pricing-table-wrapper">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th className="img-empty-heading">
													<span className="empty"></span>
												</th>
												<th className="Contributor-heading">
													<h5>Contributor Level</h5>
												</th>
												<th className="non-exclusive-heading">
													<h5>Non-Exclusive Rate</h5>
												</th>
												<th className="exclusive-heading">
													<h5>Exclusive Rate</h5>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="img-heading">
													<h6>Images</h6>
												</td>
												<td className="level">
													<span>Galaxy 1</span>
												</td>
												<td className="level">
													<span>10%</span>
												</td>
												<td className="level">
													<span>15%</span>
												</td>
											</tr>
											<tr>
												<td className="img-heading"></td>
												<td className="level">
													<span>Galaxy 2</span>
												</td>
												<td className="level">
													<span>10%</span>
												</td>
												<td className="level">
													<span>15%</span>
												</td>
											</tr>
											<tr>
												<td className="img-heading"></td>
												<td className="level">
													<span>Galaxy 3</span>
												</td>
												<td className="level">
													<span>10%</span>
												</td>
												<td className="level">
													<span>15%</span>
												</td>
											</tr>
											<tr>
												<td className="img-heading"></td>
												<td className="level">
													<span>Galaxy 4</span>
												</td>
												<td className="level">
													<span>10%</span>
												</td>
												<td className="level">
													<span>15%</span>
												</td>
											</tr>
											<tr>
												<td className="img-heading"></td>
												<td className="level">
													<span>Galaxy 5</span>
												</td>
												<td className="level">
													<span>10%</span>
												</td>
												<td className="level">
													<span>15%</span>
												</td>
											</tr>
											<tr>
												<td className="img-heading"></td>
												<td className="level">
													<span>Galaxy 6</span>
												</td>
												<td className="level">
													<span>10%</span>
												</td>
												<td className="level">
													<span>15%</span>
												</td>
											</tr>
											<tr>
												<td className="img-heading"></td>
												<td className="level">
													<span>Galaxy 7</span>
												</td>
												<td className="level">
													<span>10%</span>
												</td>
												<td className="level">
													<span>15%</span>
												</td>
											</tr>
											<tr>
												<td className="img-heading">
													<span className="empty">
														<h6>Videos</h6>
													</span>
												</td>
												<td className="level">
													<span>n / a</span>
												</td>
												<td className="level">
													<span>30 %</span>
												</td>
												<td className="level">
													<span>35%</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-8">
							<div className="pricing-below-content-wrapper">
								<p>
									IMAGES include all image types - such as photos,
									illustrations, vectors, backgrounds (videos are not included
									in this category)
								</p>
								<p>
									Contributor Level is defined by the total sales, within a
									given calendar year of an individual Contributor's images.
								</p>
								<div className="pricing-list-wrapper">
									<ul>
										<li>
											Total sales within a given calendar year is between 1 and
											100 images
										</li>
										<li>
											Total sales within a given calendar year is between 101
											and 250 images
										</li>
										<li>
											Total sales within a given calendar year is between 251
											and 500 images
										</li>
										<li>
											Total sales within a given calendar year is between 501
											and 5,000 images
										</li>
										<li>
											Total sales within a given calendar year is between 5,001
											and 25,000 images
										</li>
										<li>
											Total sales within a given calendar year is between 25,001
											and 100,000 images
										</li>
										<li>
											Total sales within a given calendar year is more than
											100,000 images
										</li>
									</ul>
								</div>
								<p>
									Non-Exclusive Rate refers to the commission paid to a
									contributor for each of their images sold, based on the
									purchase price of that image and when that Contributor is
									registered as a Non-exclusive Contributor.
								</p>
								<p>
									Exclusive Rate refers to the commission paid to a contributor
									for each of their images sold, based on the purchase price of
									that image and when that Contributor is registered as an
									Exclusive Contributor.
								</p>
								<div className="btn-wrapper">
									<a href="/agreement" className="btn">
										Contributor Agreement
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Pricing starts here */}
			{/* Faq starts here */}
			<section className="faq faq-con">
				<div className="container">
					<div className="row heading-row">
						<div className="col-lg-12 text-center">
							<div className="faq-heading-wrapper">
								<h3>Contributor Faq</h3>
							</div>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="faq-wrapper">
								<div className="accordion" id="accordionExample">
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingOne">
											<button
												className="accordion-button"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseOne"
												aria-expanded="true"
												aria-controls="collapseOne"
											>
												Lorem ipsum doler sit amet?
											</button>
										</h2>
										<div
											id="collapseOne"
											className="accordion-collapse collapse show"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Luctus venenatis lectus magna fringilla
												urna.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingTwo">
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseTwo"
												aria-expanded="false"
												aria-controls="collapseTwo"
											>
												Lorem ipsum doler sit amet?
											</button>
										</h2>
										<div
											id="collapseTwo"
											className="accordion-collapse collapse"
											aria-labelledby="headingTwo"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Luctus venenatis lectus magna fringilla
												urna.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingThree">
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseThree"
												aria-expanded="false"
												aria-controls="collapseThree"
											>
												Lorem ipsum doler sit amet?
											</button>
										</h2>
										<div
											id="collapseThree"
											className="accordion-collapse collapse"
											aria-labelledby="headingThree"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Luctus venenatis lectus magna fringilla
												urna.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingFour">
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseFour"
												aria-expanded="false"
												aria-controls="collapseFour"
											>
												Lorem ipsum doler sit amet?
											</button>
										</h2>
										<div
											id="collapseFour"
											className="accordion-collapse collapse"
											aria-labelledby="headingFour"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Luctus venenatis lectus magna fringilla
												urna.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingThree">
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseFive"
												aria-expanded="false"
												aria-controls="collapseFive"
											>
												Lorem ipsum doler sit amet?
											</button>
										</h2>
										<div
											id="collapseFive"
											className="accordion-collapse collapse"
											aria-labelledby="headingFive"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Luctus venenatis lectus magna fringilla
												urna.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* Faq ends here */}
			{/* Promotion sec starts here */}
			<section className="join contributor-join">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="promotion-card-1-wrapper">
								<div className="promotion-card-1-content-wrapper">
									<p>Join Us</p>
									<h4>
										Join Us Today As
										<br /> A Contributing Artist
									</h4>
									<a href="/register-contributor" className="btn">
										Sign Me Up!
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Promotion sec ends here */}
			<Footer />
		</>
	);
};

export default Contributors;
