import React, { useEffect, useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-news.css";
import { BlogImg1 } from "../../constant";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";

const ArtistNews = () => {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);
	const news = [
		{
			id: 1,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 2,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 3,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 4,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 5,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
		{
			id: 6,
			name: "Discover High Quality, Stock Photos And Vectors",
			img: BlogImg1,
			des: "Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.",
		},
	];
	return (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}
			{/* Blog Sec Start Here */}
			<section className="blog_sec artist-news-sec">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>News</h1>
					</div>
					<div className="row">
						{loading ? (
							<Skeleton count={news.length} baseColor="#05092cc7" />
						) : (
							news.map((item, index) => {
								return (
									<>
										<div key={index} className="col-lg-4">
											<div className="blog_box">
												<div className="blog-img">
													<figure>
														<Link to="/artist-news-detail">
															<img
																src={item?.img}
																alt={item?.name}
																className="img-fluid"
															/>
														</Link>
													</figure>
													<div className="date">
														<h4>
															02 <span>Mar</span>
														</h4>
													</div>
												</div>
												<div className="blog-detail">
													<div className="blog-description">
														<h2>{item?.name}</h2>
														<p>{item?.des}</p>
														<a href="/artist-news-detail">Learn More...</a>
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})
						)}
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="paginations-wrapper">
								<ul>
									<li className="pagination-item">
										<button className="prev pagination-link">
											<i className="fa fa-arrow-left"></i>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>01.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link active pagination-link">
											{" "}
											<span>02.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>03.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>04.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="next pagination-link">
											<i className="fa fa-arrow-right"></i>
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistNews;
