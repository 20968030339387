import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/about.css";
import {
	aboutpageimg,
	storyimg,
	sue1,
	sue2,
	team1,
	team2,
	team3,
	team4,
	visionimg,
} from "../../constant";
import GoTop from "../../components/GoTop";

const About = () => {
	return (
		<>
			<GoTop />
			<Header />
			{/* About page header starts here */}
			<section className="about-page-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="about-page-header-content-wrapper">
								<h1>About</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About page header ends here */}
			{/* our mission starts here */}
			<section className="our-mission">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left">
								<figure>
									<img src={aboutpageimg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>Our Mission</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* our vision starts here */}
			<section className="our-vision">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>Our Vision</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-right">
								<figure>
									<img src={visionimg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our vision ends here */}

			{/* our story starts here */}
			<section className="our-story">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="divider-wrapper">
								<div className="divider-line"></div>
							</div>
						</div>
					</div>
					<div className="row my-5">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="story-heading-wrapper">
								<h2>Our Story</h2>
							</div>
							<div className="story-card-wrapper">
								<div className="story-content-wrapper">
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
									</p>
								</div>
								<div className="story-img-wrapper">
									<figure>
										<img src={storyimg} className="img-fluid" alt="" />
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-12">
							<div className="divider-wrapper">
								<div className="divider-line"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our story ends here */}
			{/* our mission starts here */}
			<section className="our-mission mt-5 pt-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left">
								<figure>
									<img src={sue1} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>Meet Mick</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* our vision starts here */}
			<section className="our-vision">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>Meet Sue</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-right">
								<figure>
									<img src={sue2} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our vision ends here */}
			{/* MOONSCAPE team starts here */}
			<section className="moonscape-team">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="divider-wrapper">
								<div className="divider-line"></div>
							</div>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-12">
							<div className="teams-main-heading-wrapper">
								<h2>Moonscape Team Members</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3">
							<div className="team-card-wrapper">
								<div className="team-img-wrapper">
									<figure>
										<img src={team1} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="team-content-wrapper">
									<h4>John Doe</h4>
									<p>Lorem ipsum dolor sit amet, consectetur</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="team-card-wrapper">
								<div className="team-img-wrapper">
									<figure>
										<img src={team2} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="team-content-wrapper">
									<h4>John Doe</h4>
									<p>Lorem ipsum dolor sit amet, consectetur</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="team-card-wrapper">
								<div className="team-img-wrapper">
									<figure>
										<img src={team3} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="team-content-wrapper">
									<h4>John Doe</h4>
									<p>Lorem ipsum dolor sit amet, consectetur</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="team-card-wrapper">
								<div className="team-img-wrapper">
									<figure>
										<img src={team4} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="team-content-wrapper">
									<h4>John Doe</h4>
									<p>Lorem ipsum dolor sit amet, consectetur</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* MOONSCAPE team ends here */}
			<Footer />
		</>
	);
};

export default About;
