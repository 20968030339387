import React, { useState } from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/upload-work.css";

import { upload1, upload2, upload3, upload4 } from "../../constant";
const UploadedWork = () => {
	const uploadWork = [
		{
			id: 1,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload1,
		},
		{
			id: 2,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload2,
		},
		{
			id: 3,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload3,
		},
		{
			id: 4,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload4,
		},
		{
			id: 5,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload1,
		},
		{
			id: 6,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload1,
		},
		{
			id: 7,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload2,
		},
		{
			id: 8,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload3,
		},
		{
			id: 9,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload4,
		},
		{
			id: 10,
			name: "Etiam ultricies m egt montes,se Form.png",
			img: upload1,
		},
	];
	return (
		<>
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-9">
							<div className="uploaded-work-wrapper">
								<div className="uploaded-heading-wrapper">
									<h2>Uploaded Work</h2>
								</div>
								<div className="uploaded-table-wrapper">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th className="img-col"></th>
													<th className="title-heading">Title</th>
													<th className="Actions-heading">Actions</th>
												</tr>
											</thead>
											<tbody>
												{uploadWork.map((item, index) => {
													return (
														<>
															<tr key={index}>
																<td className="img-wrapper">
																	<figure>
																		<img
																			src={item?.img}
																			className="img-fluid"
																			alt=""
																		/>
																	</figure>
																</td>
																<td className="title-head">
																	<h5>{item?.name}</h5>
																</td>
																<td className="actions-btn-wrapper">
																	<div className="btn-wrapper">
																		<a href="/product-detail">View</a>
																		<a href="#">Download </a>
																		<a href="/removal-content2">Remove</a>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default UploadedWork;
