import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { BlogImg1, BlogImg2, BlogImg3, RecentNews1 } from "../../constant";
import "../../assets/css/blog.css";

const BlogDetail = () => {
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Blog Sec Start Here */}
            <section className="blog_sec blog-detail">
                <div className="container">
                    <div className="testimonials-wrapper">
                        <h1>Blog</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="all_blogs">
                                <div className="blog_box">
                                    <div className="blog-img">
                                        <figure>
                                            <img src={BlogImg1} alt="" className="img-fluid" />
                                        </figure>
                                        <div className="date">
                                            <h4>
                                                02 <span>Mar</span>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="blog-detail">
                                        <div className="posted">
                                            <h5>
                                                Stock Image / <span>John Doe</span>{" "}
                                            </h5>
                                        </div>
                                        <div className="blog-description">
                                            <h2>Discover High Quality, Stock Photos And Vectors</h2>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus dignissimos eum optio? Quaerat aliquid possimus blanditiis amet! Incidunt neque reprehenderit suscipit minus repellat corporis magnam? Eos dolorum corrupti laboriosam non eaque, dolorem vel nulla suscipit in. Sint beatae unde ut esse. Hic, laboriosam, quaerat, eaque quisquam nisi iusto temporibus facilis voluptatum iste nulla excepturi tempora! Temporibus, magni nisi facilis consequatur voluptatem odio provident quas modi dolores qui consectetur, fugit officiis recusandae laboriosam libero iusto? Ea possimus a unde officia ut ipsum omnis quae, sit quasi id non quis incidunt obcaecati odio minus voluptatibus maxime iste eum esse eius ab nostrum?<br /><br />Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus dignissimos eum optio? Quaerat aliquid possimus blanditiis amet! Incidunt neque reprehenderit suscipit minus repellat corporis magnam? Eos dolorum corrupti laboriosam non eaque, dolorem vel nulla suscipit in. Sint beatae unde ut esse. Hic, laboriosam, quaerat, eaque quisquam nisi iusto temporibus facilis voluptatum iste nulla excepturi tempora! Temporibus, magni nisi facilis consequatur voluptatem odio provident quas modi dolores qui consectetur, fugit officiis recusandae laboriosam libero iusto? Ea possimus a unde officia ut ipsum omnis quae, sit quasi id non quis incidunt obcaecati odio minus voluptatibus maxime iste eum esse eius ab nostrum?<br /><br />
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus dignissimos eum optio? Quaerat aliquid possimus blanditiis amet! Incidunt neque reprehenderit suscipit minus repellat corporis magnam? Eos dolorum corrupti laboriosam non eaque, dolorem vel nulla suscipit in. Sint beatae unde ut esse. Hic, laboriosam, quaerat, eaque quisquam nisi iusto temporibus facilis voluptatum iste nulla excepturi tempora! Temporibus, magni nisi facilis consequatur voluptatem odio provident quas modi dolores qui consectetur, fugit officiis recusandae laboriosam libero iusto? Ea possimus a unde officia ut ipsum omnis quae, sit quasi id non quis incidunt obcaecati odio minus voluptatibus maxime iste eum esse eius ab nostrum
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Blog Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default BlogDetail;
